import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import i18next from 'i18next'

const DEFAULT_MESSAGE = i18next.t('unexpected_error')

const Notification = (() => {
  const toastOptions = {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'colored',
  }

  toast.configure()

  const error = (message = DEFAULT_MESSAGE) => {
    toast.error(message, toastOptions)
  }

  const success = (message) => {
    toast.success(message, toastOptions)
  }

  return {
    error,
    success,
  }
})()

export default Notification
