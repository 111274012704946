import React from 'react'

export const IconDown = () => (
  <span style={{ marginLeft: '0.5rem' }}>
    <i className="fa fa-sort-down fs-5" />
  </span>
)

export const IconUp = () => (
  <span style={{ marginLeft: '0.5rem' }}>
    <i className="fa fa-sort-up fs-5" />
  </span>
)

export const Icon = () => (
  <span style={{ color: '#bbbbbb', marginLeft: '0.5rem' }}>
    <i className="fa fa-sort fs-5" />
  </span>
)
