import { createReducer, isAnyOf } from '@reduxjs/toolkit'
import { LoadingStatus } from 'common/enums/enums'
import { getDeviceStatsByStatus } from './actions'

const initialState = {
  deviceStats: null,
  loading: LoadingStatus.IDLE,
}

/* eslint no-param-reassign: off */
const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getDeviceStatsByStatus.fulfilled, (state, action) => {
      state.deviceStats = action.payload
      state.loading = LoadingStatus.SUCCEEDED
    })
    .addMatcher(isAnyOf(getDeviceStatsByStatus.rejected), (state) => {
      state.loading = LoadingStatus.FAILED
    })
    .addMatcher(isAnyOf(getDeviceStatsByStatus.pending), (state) => {
      state.loading = LoadingStatus.LOADING
    })
})

export { reducer }
