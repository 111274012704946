import { createReducer, isAnyOf } from '@reduxjs/toolkit'

import { LoadingStatus, UserType } from 'common/enums/enums'
import {
  clearUserById,
  createUser,
  getUser,
  getUsers,
  getUsersByType,
  updateUser,
} from './actions'

const initialState = {
  users: null,
  userById: null,
  ownerUsers: null,
  endUsers: null,
  validationError: null,
  loading: LoadingStatus.IDLE,
}

/* eslint no-param-reassign: off */
const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getUsers.fulfilled, (state, action) => {
      state.users = action.payload
      state.loading = LoadingStatus.SUCCEEDED
    })
    .addCase(getUser.fulfilled, (state, action) => {
      state.userById = action.payload
      state.loading = LoadingStatus.SUCCEEDED
    })
    .addCase(getUsersByType.fulfilled, (state, action) => {
      if (action.payload.type === UserType.OWNER) {
        state.ownerUsers = action.payload.data
      } else if (action.payload.type === UserType.END_USER) {
        state.endUsers = action.payload.data
      }
      state.loading = LoadingStatus.SUCCEEDED
    })
    .addCase(updateUser.fulfilled, (state, action) => {
      state.loading = LoadingStatus.SUCCEEDED
      state.users.data = state.users?.data.map((item) => {
        if (item.id === action.payload.id) {
          return {
            ...item,
            ...action.payload,
          }
        }
        return item
      })
    })
    .addCase(clearUserById, (state) => {
      state.userById = null
    })
    .addMatcher(
      isAnyOf(createUser.fulfilled, updateUser.fulfilled),
      (state) => {
        state.loading = LoadingStatus.SUCCEEDED
      }
    )
    .addMatcher(
      isAnyOf(
        getUsers.pending,
        getUsersByType.pending,
        createUser.pending,
        updateUser.pending
      ),
      (state) => {
        state.loading = LoadingStatus.LOADING
      }
    )
    .addMatcher(
      isAnyOf(
        getUsers.rejected,
        getUsersByType.rejected,
        createUser.rejected,
        updateUser.rejected
      ),
      (state) => {
        state.loading = LoadingStatus.FAILED
      }
    )
})

export { reducer }
