import React from 'react'

import {
  IconCalendar,
  IconRouter,
  IconStar,
  IconCalculator,
} from '@tabler/icons'
import { FormattedMessage } from 'react-intl'

import IconMessageCircleQuestion from 'components/ui/icons/IconMessageCircleQuestion'
import { HttpMethod } from '../common/enums/http/http'

const icons = {
  IconRouter,
  IconStar,
  IconCalendar,
  IconCalculator,
  IconMessageCircleQuestion,
}

export const deviceListRequests = [
  {
    pattern: '/branches/*/devices',
    httpMethod: HttpMethod.GET,
  },
]

export const detailedStateRequests = [
  {
    pattern: '/branches/*/devices',
    httpMethod: HttpMethod.GET,
  },
  {
    pattern: '/devices/*',
    httpMethod: HttpMethod.GET,
  },
  {
    pattern: '/devices/*/phones',
    httpMethod: HttpMethod.GET,
  },
  {
    pattern: '/devices/*/membranes',
    httpMethod: HttpMethod.GET,
  },
  {
    pattern: '/devices/*/logs/water/latest',
    httpMethod: HttpMethod.GET,
  },
  {
    pattern: '/models/*',
    httpMethod: HttpMethod.GET,
  },
  {
    pattern: '/users/*',
    httpMethod: HttpMethod.GET,
  },
]

export const calculationsRequests = [
  {
    pattern: '/calculations/*',
    httpMethod: HttpMethod.GET,
  },
  {
    pattern: '/models',
    httpMethod: HttpMethod.GET,
  },
  {
    pattern: '/membranes',
    httpMethod: HttpMethod.GET,
  },
  {
    pattern: '/calculations',
    httpMethod: HttpMethod.POST,
  },
]

export const branchesRequests = [
  {
    pattern: '/branches',
    httpMethod: HttpMethod.GET,
  },
]

export const usersRequests = [
  {
    pattern: '/users',
    httpMethod: HttpMethod.GET,
  },
]

export const rolesRequests = [
  {
    pattern: '/roles',
    httpMethod: HttpMethod.GET,
  },
]

export const frequentlyAskedQuestionsRequests = [
  {
    pattern: '/faq',
    httpMethod: HttpMethod.GET,
  },
]

const pages = {
  id: 'pages',
  caption: 'Pages Caption',
  type: 'group',
  children: [
    {
      id: 'devices',
      title: <FormattedMessage id="devices" defaultMessage="Devices" />,
      type: 'collapse',
      icon: icons.IconRouter,
      requests: [deviceListRequests, detailedStateRequests],
      children: [
        {
          id: 'devices_list',
          title: <FormattedMessage id="list" defaultMessage="List" />,
          type: 'item',
          url: '/app/devices/list',
          requests: deviceListRequests,
        },
        {
          id: 'detailed_state',
          title: (
            <FormattedMessage
              id="detailed_state"
              defaultMessage="Detailed state"
            />
          ),
          type: 'item',
          url: '/app/devices/detailed',
          requests: detailedStateRequests,
        },
      ],
    },
    {
      id: 'calculations',
      title: (
        <FormattedMessage id="calculations" defaultMessage="Calculations" />
      ),
      type: 'item',
      icon: icons.IconCalculator,
      url: '/app/calculations',
      requests: calculationsRequests,
    },
    {
      id: 'administration',
      title: (
        <FormattedMessage id="administration" defaultMessage="Administration" />
      ),
      type: 'collapse',
      icon: icons.IconStar,
      requests: [branchesRequests, usersRequests, rolesRequests],

      children: [
        {
          id: 'branches',
          title: <FormattedMessage id="branches" defaultMessage="Branches" />,
          type: 'item',
          url: '/app/administration/branches',
          requests: branchesRequests,
        },
        {
          id: 'users',
          title: <FormattedMessage id="users" defaultMessage="Users" />,
          type: 'item',
          url: '/app/administration/users',
          requests: usersRequests,
        },
        {
          id: 'roles',
          title: <FormattedMessage id="roles" defaultMessage="Roles" />,
          type: 'item',
          url: '/app/administration/roles',
          requests: rolesRequests,
        },
      ],
    },
    {
      id: 'frequentlyAskedQuestions',
      title: (
        <FormattedMessage
          id="frequently_asked_questions"
          defaultMessage="Frequently Asked Questions"
        />
      ),
      type: 'item',
      icon: icons.IconMessageCircleQuestion,
      url: '/app/frequently-asked-questions',
      requests: frequentlyAskedQuestionsRequests,
    },
  ],
}

export default pages
