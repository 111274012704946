import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import i18next from 'i18next'

import { StorageKey } from 'common/enums/enums'
import ActionType from './common'

const login = createAsyncThunk(
  ActionType.LOG_IN,
  async (request, { extra: { services } }) => {
    const { data } = await services.auth.login(request)

    services.storage.setItem(StorageKey.ACCESS_TOKEN, data.accessToken)
    services.storage.setItem(StorageKey.REFRESH_TOKEN, data.refreshToken)

    const { data: user } = await services.users.getUser(data.id)
    const { data: branch } = await services.branches.getBranch(data.branchId)

    return { data, branch, user }
  }
)

const logout = createAsyncThunk(
  ActionType.LOG_OUT,
  async (_, { extra: { services } }) => {
    services.storage.removeItem(StorageKey.ACCESS_TOKEN)
    services.storage.removeItem(StorageKey.REFRESH_TOKEN)
  }
)

const sendResetCode = createAsyncThunk(
  ActionType.SEND_RESET_CODE,
  async (request, { extra: { services } }) => {
    await services.auth.sendResetCode(request)
  }
)

const resetPassword = createAsyncThunk(
  ActionType.RESET_PASSWORD,
  async (request, { extra: { services } }) => {
    await services.auth.resetPassword(request)

    services.notification.success(i18next.t('password_reset_successfully'))
  }
)

const setResetCodeIsSent = createAction(
  ActionType.SET_RESET_CODE_IS_SENT,
  (data) => ({
    payload: data,
  })
)

const setPasswordIsReset = createAction(
  ActionType.SET_PASSWORD_IS_RESET,
  (data) => ({
    payload: data,
  })
)

export {
  login,
  logout,
  sendResetCode,
  resetPassword,
  setResetCodeIsSent,
  setPasswordIsReset,
}
