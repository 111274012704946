import { get } from 'helpers/http.helper'

const FrequentlyAskedQuestions = (() => {
  const getQuestions = (params) =>
    get('rodms/faq', {
      params,
    })

  return {
    getQuestions,
  }
})()

export default FrequentlyAskedQuestions
