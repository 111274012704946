import { createReducer } from '@reduxjs/toolkit'

import {
  menuOpen,
  setBorderRadius,
  setColorPreset,
  setFontFamily,
  setLocale,
  setMenu,
  setMode,
  setVolumeUnit,
} from './actions'
import { login } from '../auth/actions'

const initialState = {
  isOpen: [],
  fontFamily: `'Roboto', sans-serif`,
  volumeUnit: 'liter',
  borderRadius: 12,
  opened: true,
  colorPreset: 'default',
  mode: 'light',
  locale: 'en',
}

/* eslint no-param-reassign: off */
const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setMenu, (state, action) => {
      state.opened = action.payload
    })
    .addCase(menuOpen, (state, action) => {
      state.isOpen = [action.payload]
    })
    .addCase(setFontFamily, (state, action) => {
      state.fontFamily = action.payload
    })
    .addCase(setVolumeUnit, (state, action) => {
      state.volumeUnit = action.payload
    })
    .addCase(setBorderRadius, (state, action) => {
      state.borderRadius = action.payload
    })
    .addCase(setColorPreset, (state, action) => {
      state.colorPreset = action.payload
    })
    .addCase(setMode, (state, action) => {
      state.mode = action.payload
    })
    .addCase(setLocale, (state, action) => {
      state.locale = action.payload
    })
    .addCase(login.fulfilled, (state) => {
      state.isOpen = ['default']
    })
})

export { reducer }
