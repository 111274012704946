import { createAsyncThunk } from '@reduxjs/toolkit'

import ActionType from './common'

const getFrequentlyAskedQuestions = createAsyncThunk(
  ActionType.GET_QUESTIONS,
  async (params, { extra: { services } }) => {
    const { data } = await services.frequentlyAskedQuestions.getQuestions(
      params
    )

    return data
  }
)

export { getFrequentlyAskedQuestions }
