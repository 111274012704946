const PermissionEnum = {
  FULL_ACCESS: 10001,
  GET_FULL_ACCESS: 10002,
  POST_FULL_ACCESS: 10003,
  PUT_FULL_ACCESS: 10004,
  DELETE_FULL_ACCESS: 10005,
  MANAGE_MANY_BRANCHES: 10006,
  MANAGE_ALL_BRANCHES: 10007,
  MANAGE_ALL_ROLES: 10008,

  FULL_ACCESS_BRANCHES: 11000,
  GET_FULL_ACCESS_BRANCHES: 11001,
  POST_FULL_ACCESS_BRANCHES: 11002,
  PUT_FULL_ACCESS_BRANCHES: 11003,
  GET_BRANCH: 11004,

  FULL_ACCESS_USERS: 11100,
  GET_FULL_ACCESS_USERS: 11101,
  POST_FULL_ACCESS_USERS: 11102,
  PUT_FULL_ACCESS_USERS: 11103,
  DELETE_FULL_ACCESS_USERS: 11104,

  FULL_ACCESS_ROLES: 11200,
  GET_FULL_ACCESS_ROLES: 11201,
  POST_FULL_ACCESS_ROLES: 11202,
  PUT_FULL_ACCESS_ROLES: 11203,

  FULL_ACCESS_DEVICES: 11300,
  GET_FULL_ACCESS_DEVICES: 11301,
  POST_FULL_ACCESS_DEVICES: 11302,
  PUT_FULL_ACCESS_DEVICES: 11303,
  DELETE_FULL_ACCESS_DEVICES: 11304,
  POST_DEVICE: 11305,
  POST_TETHER_DEVICE: 11306,
  PUT_DEVICE: 11307,
  PUT_TETHER_DEVICE: 11308,
  GET_BRANCH_DEVICES: 11309,
  GET_BRANCH_DEVICES_BY_STATE: 11310,
  GET_DEVICE: 11311,

  FULL_ACCESS_STATISTICS: 11400,
  GET_FULL_ACCESS_STATISTICS: 11401,
  POST_FULL_ACCESS_STATISTICS: 11402,
  PUT_FULL_ACCESS_STATISTICS: 11403,
  DELETE_FULL_ACCESS_STATISTICS: 11404,
  GET_DEVICE_STATISTICS: 11405,

  FULL_ACCESS_DEVICE_ACTIONS: 11500,
  GET_FULL_ACCESS_DEVICE_ACTIONS: 11501,
  POST_FULL_ACCESS_DEVICE_ACTIONS: 11502,
  PUT_FULL_ACCESS_DEVICE_ACTIONS: 11503,
  DELETE_FULL_ACCESS_DEVICE_ACTIONS: 11504,

  FULL_ACCESS_DEVICE_LOGS: 11600,
  GET_FULL_ACCESS_DEVICE_LOGS: 11601,
  POST_FULL_ACCESS_DEVICE_LOGS: 11602,
  PUT_FULL_ACCESS_DEVICE_LOGS: 11603,
  DELETE_FULL_ACCESS_DEVICE_LOGS: 11604,

  FULL_ACCESS_DEVICE_MODELS: 11700,
  GET_FULL_ACCESS_DEVICE_MODELS: 11701,
  POST_FULL_ACCESS_DEVICE_MODELS: 11702,
  PUT_FULL_ACCESS_DEVICE_MODELS: 11703,
  DELETE_FULL_ACCESS_DEVICE_MODELS: 11704,

  FULL_ACCESS_DEVICE_SETTINGS: 11800,
  GET_FULL_ACCESS_DEVICE_SETTINGS: 11801,
  POST_FULL_ACCESS_DEVICE_SETTINGS: 11802,
  PUT_FULL_ACCESS_DEVICE_SETTINGS: 11803,
  DELETE_FULL_ACCESS_DEVICE_SETTINGS: 11804,
  GET_DEVICE_ALARM_SETTINGS: 11805,
  PUT_DEVICE_ALARM_SETTINGS: 11806,

  FULL_ACCESS_MEMBRANES: 11900,
  GET_FULL_ACCESS_MEMBRANES: 11901,
  POST_FULL_ACCESS_MEMBRANES: 11902,
  PUT_FULL_ACCESS_MEMBRANES: 11903,
  DELETE_FULL_ACCESS_MEMBRANES: 11904,

  FULL_ACCESS_CALCULATIONS: 12000,
  GET_FULL_ACCESS_CALCULATIONS: 12001,
  POST_FULL_ACCESS_CALCULATIONS: 12002,
  PUT_FULL_ACCESS_CALCULATIONS: 12003,
  DELETE_FULL_ACCESS_CALCULATIONS: 12004,
}

export { PermissionEnum }
