import { createAsyncThunk } from '@reduxjs/toolkit'
import ActionType from './common'

const getBranchStats = createAsyncThunk(
  ActionType.GET_BRANCH_STATS,
  async (id, { extra: { services } }) => {
    const { data } = await services.branches.getBranchStats(id)
    return data
  }
)

const getBranchDevicesByState = createAsyncThunk(
  ActionType.GET_BRANCH_DEVICES_BY_STATE,
  async ({ id, state, params }, { extra: { services } }) => {
    if (state) {
      const { data } = await services.branches.getBranchDevicesByState(
        id,
        state,
        params
      )

      return data
    }
    const { data } = await services.branches.getBranchDevices(id, params)

    return data
  }
)

export { getBranchStats, getBranchDevicesByState }
