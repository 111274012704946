import { useDispatch } from 'react-redux'
import { useState } from 'react'

const useSearch = () => {
  const dispatch = useDispatch()

  const [keyword, setKeyword] = useState(null)

  const handleSearch = (e, action) => {
    dispatch(action)
    setKeyword(e.target.value || null)
  }

  return {
    keyword,
    setKeyword,
    handleSearch,
  }
}

export default useSearch
