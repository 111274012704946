import { createReducer, isAnyOf } from '@reduxjs/toolkit'

import {
  clearFieldValidationError,
  clearSubmitted,
  saveFieldValidationError,
} from './actions'
import { createBranch } from '../administration/branches/actions'
import { createUser, updateUser } from '../administration/users/actions'
import {
  addDevice,
  createDevice,
  updateDeviceCreated,
  updateDeviceMembranes,
  updateDevicePhones,
  updateDeviceTethered,
  updateThresholdAlarm,
} from '../devices/list/actions'

const initialState = {
  submitted: false,
  validationErrors: [],
}

/* eslint no-param-reassign: off */
const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(clearSubmitted, (state) => {
      state.submitted = false
    })
    .addCase(saveFieldValidationError.fulfilled, (state, action) => {
      state.validationErrors = action.payload
    })
    .addCase(clearFieldValidationError, (state) => {
      state.validationErrors = []
    })
    .addMatcher(
      isAnyOf(
        createBranch.fulfilled,
        createUser.fulfilled,
        updateUser.fulfilled,
        createDevice.fulfilled,
        addDevice.fulfilled,
        updateDeviceCreated.fulfilled,
        updateDeviceTethered.fulfilled,
        updateDevicePhones.fulfilled,
        updateDeviceMembranes.fulfilled,
        updateThresholdAlarm.fulfilled
      ),
      (state) => {
        state.submitted = true
      }
    )
})

export { reducer }
