import React, { lazy } from 'react'
import { Navigate } from 'react-router-dom'

import Loadable from 'components/helpers/Loadable'
import Roles from 'pages/administration/Roles'
import Analytics from 'pages/analytics/Analytics'
import { hasAccess } from 'common/permissions/permissions'
import { analyticsRequests, defaultRequests } from 'menu-items/dashboard'
import {
  branchesRequests,
  calculationsRequests,
  detailedStateRequests,
  deviceListRequests,
  rolesRequests,
  usersRequests,
} from 'menu-items/pages'

const Login = Loadable(lazy(() => import('pages/auth/Login')))
const PasswordReset = Loadable(lazy(() => import('pages/auth/PasswordReset')))
const DashboardLayout = Loadable(
  lazy(() => import('components/layout/DashboardLayout'))
)
const MinimalLayout = Loadable(
  lazy(() => import('components/layout/MinimalLayout'))
)
const Dashboard = Loadable(lazy(() => import('pages/dashboard/Dashboard')))
const List = Loadable(lazy(() => import('pages/devices/List')))
const DetailedState = Loadable(
  lazy(() => import('pages/devices/DetailedState'))
)
const Calculations = Loadable(lazy(() => import('pages/calculations')))
const Branches = Loadable(lazy(() => import('pages/administration/Branches')))
const Users = Loadable(lazy(() => import('pages/administration/Users')))
const FrequentlyAskedQuestions = Loadable(
  lazy(() => import('pages/frequently-asked-questions'))
)
const Profile = Loadable(lazy(() => import('pages/profile')))

/* eslint-disable indent */
const hasAccessRoute = (requests, authorityIds) => {
  if (!authorityIds) return false
  return requests
    ? requests.every(({ httpMethod, pattern }) =>
        hasAccess(httpMethod, pattern, authorityIds)
      )
    : false
}

const computeFirstAvailable = (authorityIds) => {
  if (hasAccessRoute(defaultRequests, authorityIds)) return '/app/dashboard'
  if (hasAccessRoute(analyticsRequests, authorityIds)) return '/app/analytics'
  if (hasAccessRoute(deviceListRequests, authorityIds))
    return '/app/devices/list'
  if (hasAccessRoute(detailedStateRequests, authorityIds))
    return '/app/devices/detailed'
  if (hasAccessRoute(calculationsRequests, authorityIds))
    return '/app/calculations'
  if (hasAccessRoute(branchesRequests, authorityIds))
    return '/app/administration/branches'
  if (hasAccessRoute(usersRequests, authorityIds))
    return '/app/administration/users'
  if (hasAccessRoute(rolesRequests, authorityIds))
    return '/app/administration/roles'
  return '/app/frequently-asked-questions'
}

const routes = (isLoggedIn, authorityIds) => [
  {
    path: '/app',
    element: isLoggedIn ? <DashboardLayout /> : <Navigate to="/login" />,
    children: [
      hasAccessRoute(defaultRequests, authorityIds)
        ? { path: '/app/dashboard', element: <Dashboard /> }
        : null,
      hasAccessRoute(analyticsRequests, authorityIds)
        ? { path: '/app/analytics', element: <Analytics /> }
        : null,
      hasAccessRoute(deviceListRequests, authorityIds)
        ? { path: '/app/devices/list', element: <List /> }
        : null,
      hasAccessRoute(detailedStateRequests, authorityIds)
        ? { path: '/app/devices/detailed', element: <DetailedState /> }
        : null,
      hasAccessRoute(calculationsRequests, authorityIds)
        ? { path: '/app/calculations', element: <Calculations /> }
        : null,
      hasAccessRoute(branchesRequests, authorityIds)
        ? { path: '/app/administration/branches', element: <Branches /> }
        : null,
      hasAccessRoute(usersRequests, authorityIds)
        ? { path: '/app/administration/users', element: <Users /> }
        : null,
      hasAccessRoute(rolesRequests, authorityIds)
        ? { path: '/app/administration/roles', element: <Roles /> }
        : null,
      {
        path: '/app/frequently-asked-questions',
        element: <FrequentlyAskedQuestions />,
      },
      { path: '/app/profile', element: <Profile /> },
      {
        path: '/app',
        element: <Navigate to={computeFirstAvailable(authorityIds)} />,
      },
    ].filter((item) => item),
  },
  {
    path: '/',
    element: !isLoggedIn ? <MinimalLayout /> : <Navigate to="/app" />,
    children: [
      { path: '/login', element: <Login /> },
      { path: '/', element: <Navigate to="/login" /> },
      { path: '/*', element: <Navigate to="/login" /> },
    ],
  },
  {
    path: '/password-reset',
    element: <PasswordReset />,
  },
]

export default routes
