import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import ActionType from './common'

const clearSubmitted = createAction(ActionType.CLEAR_SUBMITTED)

const saveFieldValidationError = createAsyncThunk(
  ActionType.SAVE_FIELD_VALIDATION_ERROR,
  async (errors) => errors
)

const clearFieldValidationError = createAction(
  ActionType.CLEAR_FIELD_VALIDATION_ERROR
)

export { clearSubmitted, saveFieldValidationError, clearFieldValidationError }
