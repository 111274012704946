import { createAction } from '@reduxjs/toolkit'
import ActionType from './common'

const setMenu = createAction(ActionType.SET_MENU)
const menuOpen = createAction(ActionType.MENU_OPEN)
const setFontFamily = createAction(ActionType.SET_FONT_FAMILY)
const setVolumeUnit = createAction(ActionType.SET_VOLUME_UNIT)
const setBorderRadius = createAction(ActionType.SET_BORDER_RADIUS)
const setColorPreset = createAction(ActionType.SET_COLOR_PRESET)
const setMode = createAction(ActionType.SET_MODE)
const setLocale = createAction(ActionType.SET_LOCALE)

export {
  setMenu,
  menuOpen,
  setFontFamily,
  setVolumeUnit,
  setBorderRadius,
  setColorPreset,
  setMode,
  setLocale,
}
