import { createReducer, isAnyOf } from '@reduxjs/toolkit'

import { LoadingStatus } from 'common/enums/enums'
import {
  login,
  logout,
  sendResetCode,
  resetPassword,
  setPasswordIsReset,
  setResetCodeIsSent,
} from './actions'
import { updateProfile } from '../administration/users/actions'

const initialState = {
  authUser: null,
  user: null,
  validationError: null,
  codeIsSent: false,
  passwordIsReset: false,
  loading: LoadingStatus.IDLE,
}

/* eslint no-param-reassign: off */
const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(login.fulfilled, (state, action) => {
      state.authUser = action.payload.data
      state.user = action.payload.user
      state.validationError = null
      state.loading = LoadingStatus.SUCCEEDED
    })
    .addCase(logout.fulfilled, (state) => {
      state.authUser = null
      state.user = null
    })
    .addCase(updateProfile.fulfilled, (state, action) => {
      state.user = action.payload
    })
    .addCase(sendResetCode.fulfilled, (state) => {
      state.codeIsSent = true
    })
    .addCase(resetPassword.fulfilled, (state) => {
      state.passwordIsReset = true
    })
    .addCase(setResetCodeIsSent, (state, action) => {
      state.codeIsSent = action.payload
    })
    .addCase(setPasswordIsReset, (state, action) => {
      state.passwordIsReset = action.payload
    })
    .addMatcher(isAnyOf(login.pending), (state) => {
      state.loading = LoadingStatus.LOADING
    })
    .addMatcher(isAnyOf(login.rejected), (state, action) => {
      state.loading = LoadingStatus.FAILED
      state.validationError = action.error
    })
})

export { reducer }
