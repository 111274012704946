import axios from 'axios'
import { ENV, HttpStatusCode, StorageKey } from 'common/enums/enums'
import i18next from 'i18next'
import storage from 'services/storage/storage.service'

import { authActionCreator } from 'store/actions'

const axiosApi = axios.create({
  baseURL: ENV.API_PATH,
})

/* eslint-disable no-param-reassign */
export default {
  setupInterceptors: (store) => {
    let isAlreadyFetchingAccessToken = false
    let subscribers = []

    const REFRESH_TOKEN_URL = '/user-service/rodms/refresh-token'
    const LOGIN_URL = '/user-service/rodms/login'
    const SEND_RESET_CODE_URL = '/user-service/users/password/reset'
    const RESET_PASSWORD_URL = '/user-service/users/password'

    const onAccessTokenFetched = (accessToken) => {
      subscribers = subscribers.filter((callback) => callback(accessToken))
    }

    const addSubscriber = (callback) => subscribers.push(callback)

    const addAuthorizationHeader = (config, token) => ({
      ...config,
      headers: {
        ...config.headers,
        Authorization: `Bearer ${token}`,
      },
    })

    axiosApi.interceptors.request.use(
      (config) => {
        if (config.url !== REFRESH_TOKEN_URL) {
          const token = storage.getItem(StorageKey.ACCESS_TOKEN)
          config = addAuthorizationHeader(config, token)
        } else {
          const refreshToken = storage.getItem(StorageKey.REFRESH_TOKEN)
          config = addAuthorizationHeader(config, refreshToken)
        }
        return config
      },
      (error) =>
        Promise.reject(
          (error.response && error.response.data) || 'Wrong Services'
        )
    )

    axiosApi.interceptors.response.use(
      (response) => response,
      (error) => {
        let originalRequest = error.config
        if (
          !error.config.url.endsWith(REFRESH_TOKEN_URL) &&
          !error.config.url.endsWith(LOGIN_URL) &&
          !error.config.url.endsWith(SEND_RESET_CODE_URL) &&
          !error.config.url.endsWith(RESET_PASSWORD_URL)
        ) {
          if (!isAlreadyFetchingAccessToken) {
            isAlreadyFetchingAccessToken = true
            const refreshToken = storage.getItem(StorageKey.REFRESH_TOKEN)
            axiosApi
              .post(
                REFRESH_TOKEN_URL,
                {
                  token: refreshToken,
                },
                {
                  headers: { Authorization: `Bearer ${refreshToken}` },
                }
              )
              .then((res) => {
                storage.setItem(StorageKey.ACCESS_TOKEN, res.data.accessToken)
                storage.setItem(StorageKey.REFRESH_TOKEN, res.data.refreshToken)

                isAlreadyFetchingAccessToken = false

                onAccessTokenFetched(storage.getItem(StorageKey.ACCESS_TOKEN))
              })
              .catch((err) => {
                if (err.path.endsWith(REFRESH_TOKEN_URL)) {
                  store.dispatch(authActionCreator.logout())
                }
              })
          }

          return new Promise((resolve) => {
            addSubscriber((accessToken) => {
              originalRequest = addAuthorizationHeader(
                originalRequest,
                accessToken
              )
              resolve(axios(originalRequest))
            })
          })
        }
        return Promise.reject(
          (error.response && error.response.data) || 'Wrong Services'
        )
      }
    )
  },
}
/* eslint-enable no-param-reassign */

class ValidationError extends Error {
  constructor(message) {
    super(message)
    this.name = 'FieldErrors'
  }
}

const get = (url, config) =>
  axiosApi.get(url, { ...config }).catch((error) => {
    throw error.message || error.response?.data || error
  })

const post = (url, data, config, dispatch, action) =>
  axiosApi.post(url, { ...data }, { ...config }).catch((error) => {
    if (error.response?.status === HttpStatusCode.BAD_REQUEST) {
      throw new ValidationError(JSON.stringify(error.response.data.fieldErrors))
    } else if (
      error.response?.status === HttpStatusCode.UNPROCESSABLE_ENTITY ||
      error.response?.status === HttpStatusCode.NOT_FOUND
    ) {
      if (url === 'rodms/calculations') dispatch(action())
      throw i18next.t(error.response.data.message, {
        defaultValue: error.response.data.message,
      })
    }
    throw i18next.t(error.message, { defaultValue: error.message })
  })

const put = (url, data, config) =>
  axiosApi.put(url, { ...data }, { ...config }).catch((error) => {
    if (error.response?.status === HttpStatusCode.BAD_REQUEST) {
      throw new ValidationError(JSON.stringify(error.response.data.fieldErrors))
    } else if (error.response?.status === HttpStatusCode.UNPROCESSABLE_ENTITY) {
      throw error.response.data.message
    }
    throw error.message || error.response?.data || error
  })

const patch = (url, data, config) =>
  axiosApi.patch(url, { ...data }, { ...config }).catch((error) => {
    if (error.response?.status === HttpStatusCode.BAD_REQUEST) {
      if (error.response?.data?.fieldErrors) {
        throw new ValidationError(
          JSON.stringify(error.response.data.fieldErrors)
        )
      }

      throw error.response?.data?.message
    } else if (
      error.response?.status === HttpStatusCode.UNPROCESSABLE_ENTITY ||
      error.response?.status === HttpStatusCode.NOT_FOUND
    ) {
      throw error.response?.data?.message
    }
    throw error.message
  })

const del = (url, config) =>
  axiosApi.delete(url, { ...config }).catch((error) => {
    throw error.message || error.response.data || error
  })

export { get, post, put, patch, del }
