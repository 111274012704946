import { createAsyncThunk } from '@reduxjs/toolkit'
import ActionType from './common'

const getDeviceStatsByStatus = createAsyncThunk(
  ActionType.GET_DEVICE_STATS_BY_STATUS,
  async (id, { extra: { services } }) => {
    const { data } = await services.devices.getDeviceStatsByStatus()
    return data
  }
)

export { getDeviceStatsByStatus }
