import { useDispatch } from 'react-redux'
import { useState } from 'react'
import { PaginationEnum } from 'common/enums/enums'

const usePagination = () => {
  const dispatch = useDispatch()

  const [anchorEl, setAnchorEl] = useState(null)
  const [pageSize, setPageSize] = useState(PaginationEnum.DEFAULT_PAGE_SIZE)
  const [page, setPage] = useState(PaginationEnum.DEFAULT_PAGE)

  const handlePageChange = (p, action) => {
    dispatch(action)
    setPage(p)
  }

  const handlePageSizerClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  /* eslint-disable no-restricted-globals */
  const handlePageSizerClose = (ps, action) => {
    setAnchorEl(null)

    if (isNaN(ps)) {
      return
    }

    setPageSize(ps)
    dispatch(action)
    setPage(PaginationEnum.DEFAULT_PAGE)
  }

  return {
    anchorEl,
    page,
    pageSize,
    setPage,
    handlePageChange,
    handlePageSizerClick,
    handlePageSizerClose,
  }
}

export default usePagination
