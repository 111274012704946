const ActionType = {
  GET_DEVICES: 'devices/get-devices',
  GET_MEMBRANES: 'devices/get-membranes',
  GET_DEVICE: 'devices/get-device',
  GET_DEVICE_PHONES: 'devices/get-device-phones',
  GET_DEVICE_MEMBRANES: 'devices/get-device-membranes',
  GET_DEVICE_STATS: 'devices/get-device-stats',
  GET_DEVICE_WATER_LOGS_LATEST: 'devices/get-device-water-logs-latest',
  GET_DEVICE_SETTINGS: 'devices/get-device-settings',
  GET_DEVICE_ALARM_SETTINGS: 'devices/get-device-alarm-settings',
  GET_DEVICE_THRESHOLDS_SETTINGS: 'devices/get-device-thresholds-settings',
  GET_DEVICE_ALARM_HISTORY: 'devices/get-device-alarm-history',
  GET_DEVICE_ANALOG_LOGS_LATEST: 'devices/get-device-analog-logs-latest',
  GET_DEVICE_SETTING_VALUES: 'devices/get-device-setting-values',
  POST_RESET_DEVICE_THRESHOLDS_SETTINGS:
    'devices/post-resert-device-thresholds-settings',
  CREATE_DEVICE: 'devices/post-device',
  ADD_DEVICE: 'devices/add-device',
  UPDATE_DEVICE_TETHERED: 'devices/update-device-tethered',
  UPDATE_DEVICE_PHONES: 'devices/update-device-phones',
  UPDATE_DEVICE_MEMBRANES: 'devices/update-device-membranes',
  UPDATE_DEVICE_FIRMWARE: 'devices/update-device-firmware',
  UPDATE_DEVICE_CREATED: 'devices/update-device-created',
  UPDATE_DEVICE_SETTINGS: 'devices/update-device-settings',
  UPDATE_DEVICE_ALARM_SETTING: 'devices/update-device-alarm-setting',
  UPDATE_DEVICE_SETTING: 'devices/update-device-setting',
  UPDATE_THRESHOLD_ALARM: 'devices/update-threshold-alarm',
  CLEAR_DEVICE_BY_ID: 'devices/clear-device-by-id',
  CLEAR_DEVICE_CHART_DATA: 'devices/clear-device-chart-data',
  CLEAR_DEVICE_SETTINGS: 'devices/clear-device-settings',
  CLEAR_DEVICE_ALARM_SETTINGS: 'devices/clear-device-alarm-settings',
  CLEAR_DEVICE_THRESHOLDS_SETTINGS: 'devices/clear-device-thresholds-settings',
  CLEAR_DEVICE_ALARM_HISTORY: 'devices/clear-device-alarm-history',
  SET_SETTING_DATA: 'devices/set-setting-data',
  SET_LOADING: 'devices/set-loading',
}

export default ActionType
