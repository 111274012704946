import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import i18next from 'i18next'

import { IntlProvider } from 'react-intl'
import config from 'config'
import resourcesToBackend from 'i18next-resources-to-backend'

const loadLocaleData = (locale) => {
  switch (locale) {
    case 'uk':
      return import('locales/uk.json')
    default:
      return import('locales/en.json')
  }
}

i18next
  .use(
    resourcesToBackend((language, namespace, callback) => {
      import(`locales/${language}.json`)
        .then((resources) => {
          callback(null, resources)
        })
        .catch((error) => {
          callback(error, null)
        })
    })
  )
  .init({
    initImmediate: true,
    lng: 'en',
    fallbackLng: 'en',
  })
  .then()

const Locales = ({ children }) => {
  const customization = useSelector((state) => state.customization)
  const [messages, setMessages] = useState()

  useEffect(() => {
    loadLocaleData(customization.locale).then((dict) => {
      setMessages(dict.default)
    })
  }, [customization.locale])

  useEffect(async () => {
    await i18next.changeLanguage(customization.locale)
  }, [customization.locale])

  return (
    <>
      {messages && (
        <IntlProvider
          locale={customization.locale}
          defaultLocale={config.defaultLocale}
          messages={messages}
        >
          {children}
        </IntlProvider>
      )}
    </>
  )
}

Locales.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Locales
