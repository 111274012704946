const ActionType = {
  SET_BORDER_RADIUS: 'customization/set-border-radius',
  SET_FONT_FAMILY: 'customization/set-font-family',
  SET_VOLUME_UNIT: 'customization/set-volume-unit',
  SET_MENU: 'customization/set-menu',
  MENU_OPEN: 'customization/menu-open',
  SET_COLOR_PRESET: 'customization/set-color-preset',
  SET_MODE: 'customization/set-mode',
  SET_LOCALE: 'customization/set-locale',
}

export default ActionType
