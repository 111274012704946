import { get } from 'helpers/http.helper'

const Roles = (() => {
  const getRoles = (params) =>
    get('/user-service/roles', {
      params,
    })

  const getRole = (id) => get(`/user-service/roles/${id}`)

  return {
    getRoles,
    getRole,
  }
})()

export default Roles
