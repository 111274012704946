import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import i18next from 'i18next'
import ActionType from './common'

const getUsers = createAsyncThunk(
  ActionType.GET_USERS,
  async (params, { extra: { services } }) => {
    const { data } = await services.users.getUsers(params)

    return data
  }
)

const getUser = createAsyncThunk(
  ActionType.GET_USER,
  async (id, { extra: { services } }) => {
    const { data } = await services.users.getUser(id)

    return { ...data }
  }
)

const clearUserById = createAction(ActionType.CLEAR_USER_BY_ID)

const getUsersByType = createAsyncThunk(
  ActionType.GET_USERS_BY_TYPE,
  async ({ type, params }, { extra: { services } }) => {
    const { data } = await services.users.getUsersByType(type, params)

    return { data, type }
  }
)

const createUser = createAsyncThunk(
  ActionType.CREATE_USER,
  async (payload, { extra: { services } }) => {
    await services.users.createUser(payload)

    services.notification.success(i18next.t('user_created_successfully'))
  }
)

const updateUser = createAsyncThunk(
  ActionType.UPDATE_USER,
  async ({ id, payload, additional }, { extra: { services } }) => {
    await services.users.updateUser(id, payload)

    services.notification.success(i18next.t('user_updated_successfully'))
    return {
      id,
      ...payload,
      role: additional.role.name,
    }
  }
)

const updateProfile = createAsyncThunk(
  ActionType.UPDATE_PROFILE,
  async ({ id, payload }, { extra: { services } }) => {
    await services.users.updateProfile(id, payload)

    services.notification.success(i18next.t('profile_updated_successfully'))
    return {
      id,
      ...payload,
    }
  }
)

const updatePassword = createAsyncThunk(
  ActionType.UPDATE_PROFILE,
  async ({ id, payload }, { extra: { services } }) => {
    await services.users.updateProfile(id, payload)

    services.notification.success(i18next.t('password_updated_successfully'))
  }
)

export {
  getUsers,
  getUser,
  getUsersByType,
  createUser,
  updateUser,
  updateProfile,
  updatePassword,
  clearUserById,
}
