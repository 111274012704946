import React, { Suspense } from 'react'
import Loader from 'components/ui/Loader'

/* eslint-disable react/function-component-definition */
const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<Loader />}>
      <Component {...props} />
    </Suspense>
  )

export default Loadable
