import { post } from 'helpers/http.helper'

const Auth = (() => {
  const login = (request) => post('/user-service/rodms/login', request)

  const refreshToken = (request) =>
    post('/user-service/rodms/refresh-token', request)

  const sendResetCode = (request) =>
    post('/user-service/users/password/reset', request)

  const resetPassword = (request) =>
    post('/user-service/users/password', request)

  return {
    login,
    refreshToken,
    sendResetCode,
    resetPassword,
  }
})()

export default Auth
