import { createReducer, isAnyOf } from '@reduxjs/toolkit'

import { LoadingStatus } from 'common/enums/enums'
import { loadBranches, setSelectedBranch } from './actions'
import { login } from '../auth/actions'

const initialState = {
  branches: [],
  selectedBranch: null,
  validationError: null,
  loading: LoadingStatus.IDLE,
}

/* eslint no-param-reassign: off */
const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(loadBranches.fulfilled, (state, action) => {
      state.branches = action.payload
      state.loading = LoadingStatus.SUCCEEDED
    })
    .addCase(setSelectedBranch.fulfilled, (state, action) => {
      state.selectedBranch = action.payload
    })
    .addCase(login.fulfilled, (state, action) => {
      state.selectedBranch = action.payload.branch
    })
    .addMatcher(isAnyOf(loadBranches.pending), (state) => {
      state.loading = LoadingStatus.LOADING
    })
    .addMatcher(isAnyOf(loadBranches.rejected), (state, action) => {
      state.loading = LoadingStatus.FAILED
      state.validationError = action.error
    })
})

export { reducer }
