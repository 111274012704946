const ActionType = {
  LOG_IN: 'auth/log-in',
  LOG_OUT: 'auth/log-out',
  SEND_RESET_CODE: 'auth/send-reset-code',
  RESET_PASSWORD: 'auth/reset-password',
  SET_RESET_CODE_IS_SENT: 'auth/set-code_is_sent',
  SET_PASSWORD_IS_RESET: 'auth/set-password-is-reset',
}

export default ActionType
