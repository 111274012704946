import { createAsyncThunk } from '@reduxjs/toolkit'
import ActionType from './common'

const loadBranches = createAsyncThunk(
  ActionType.LOAD_BRANCHES,
  async (params, { extra: { services } }) => {
    const { data } = await services.branches.getBranches(params)

    return data
  }
)

const setSelectedBranch = createAsyncThunk(
  ActionType.SET_SELECTED_BRANCH,
  async (newBranch) => newBranch
)

export { loadBranches, setSelectedBranch }
