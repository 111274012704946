import { createReducer, isAnyOf } from '@reduxjs/toolkit'

import { LoadingStatus } from 'common/enums/enums'
import { getModels } from './actions'

const initialState = {
  models: null,
  validationError: null,
  loading: LoadingStatus.IDLE,
}

/* eslint no-param-reassign: off */
const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getModels.fulfilled, (state, action) => {
      state.models = action.payload
      state.loading = LoadingStatus.SUCCEEDED
    })
    .addMatcher(isAnyOf(getModels.pending), (state) => {
      state.loading = LoadingStatus.LOADING
    })
    .addMatcher(isAnyOf(getModels.rejected), (state) => {
      state.loading = LoadingStatus.FAILED
    })
})

export { reducer }
