import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { Grid, InputAdornment, OutlinedInput, Pagination } from '@mui/material'
import { IconSearch } from '@tabler/icons'

import { injectIntl } from 'react-intl'

import MainCard from 'components/ui/cards/MainCard'
import { gridSpacing } from 'themes/common/constants'
import * as rolesActionCreator from 'store/administration/roles/actions'
import { calculateOffset, countPagesAmount } from 'helpers/pagination.helper'
import PageSizer from 'components/helpers/PageSizer'
import { PageSize, PaginationEnum } from 'common/enums/enums'

import usePagination from 'hooks/usePagination'
import useSearch from 'hooks/useSearch'
import useSort from 'hooks/useSort'
import RoleList from './components/RoleList'

const Roles = ({ intl }) => {
  const dispatch = useDispatch()

  const [rows, setRows] = useState([])

  const {
    anchorEl,
    page,
    pageSize,
    setPage,
    handlePageSizerClick,
    handlePageSizerClose,
    handlePageChange,
  } = usePagination()

  const { keyword, handleSearch } = useSearch()

  const { orderBy, orderType, handleRequestSort } = useSort()

  const { roles } = useSelector((state) => state.roles)

  useEffect(() => {
    dispatch(
      rolesActionCreator.getRoles({
        limit: PaginationEnum.DEFAULT_LIMIT,
        offset: PaginationEnum.DEFAULT_OFFSET,
      })
    )
  }, [])

  useEffect(() => {
    if (roles) {
      setRows(roles.data)
    }
  }, [roles])

  const handlePageNChange = (p) =>
    handlePageChange(
      p,
      rolesActionCreator.getRoles({
        limit: pageSize,
        offset: calculateOffset(p, pageSize),
        keyword,
        orderBy,
        orderType,
      })
    )

  const handleSort = (property) => {
    handleRequestSort(property, (newOrderBy, newOrderType) =>
      rolesActionCreator.getRoles({
        limit: pageSize,
        offset: PaginationEnum.DEFAULT_OFFSET,
        keyword,
        orderBy: newOrderBy,
        orderType: newOrderType,
      })
    )
    setPage(PaginationEnum.DEFAULT_PAGE)
  }

  const handleSearchByKeyword = (e) => {
    handleSearch(
      e,
      rolesActionCreator.getRoles({
        limit: pageSize,
        offset: PaginationEnum.DEFAULT_OFFSET,
        keyword: e.target.value,
        orderBy,
        orderType,
      })
    )
    setPage(PaginationEnum.DEFAULT_PAGE)
  }

  const handleSelectPageClose = (ps) =>
    handlePageSizerClose(
      ps,
      rolesActionCreator.getRoles({
        limit: ps,
        offset: PaginationEnum.DEFAULT_OFFSET,
        keyword,
        orderBy,
        orderType,
      })
    )

  return (
    <MainCard
      title={
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          spacing={gridSpacing}
        >
          <Grid item>
            <OutlinedInput
              id="input-search-list"
              placeholder={intl.formatMessage({ id: 'search' })}
              onChange={handleSearchByKeyword}
              startAdornment={
                <InputAdornment position="start">
                  <IconSearch stroke={1.5} size="1rem" />
                </InputAdornment>
              }
              size="small"
            />
          </Grid>
        </Grid>
      }
      content={false}
    >
      <RoleList
        rows={rows}
        orderBy={orderBy}
        orderType={orderType}
        handleRequestSort={handleSort}
      />
      <Grid item xs={12} sx={{ p: 3 }}>
        <Grid container justifyContent="space-between" spacing={gridSpacing}>
          <Grid item>
            <Pagination
              count={roles ? countPagesAmount(roles.total, pageSize) : 1}
              onChange={(_, p) => handlePageNChange(p)}
              page={page}
              color="primary"
            />
          </Grid>
          <Grid item>
            <PageSizer
              anchorEl={anchorEl}
              pageSize={pageSize}
              handleClick={handlePageSizerClick}
              handleClose={handleSelectPageClose}
              sizeOptions={[
                PageSize.SIZE_10,
                PageSize.SIZE_20,
                PageSize.SIZE_50,
              ]}
            />
          </Grid>
        </Grid>
      </Grid>
    </MainCard>
  )
}

/* eslint-disable react/forbid-prop-types */
Roles.propTypes = {
  intl: PropTypes.object.isRequired,
}

export default injectIntl(Roles)
