import { notification } from 'services/services'
import { saveFieldValidationError } from 'store/common/actions'

/* eslint-disable indent */
const handleError =
  ({ dispatch }) =>
  (next) =>
    // eslint-disable-next-line func-names
    function (action) {
      // eslint-disable-next-line react/destructuring-assignment
      if (action?.error) {
        // eslint-disable-next-line react/destructuring-assignment
        const { name, message } = action.error
        if (name === 'FieldErrors') {
          dispatch(saveFieldValidationError(JSON.parse(message)))
          return next(action)
        }
        notification.error(message)
      }

      return action ? next(action) : null
    }

export { handleError }
