import React, { useEffect, useMemo } from 'react'
import { Grid } from '@mui/material'
import { gridSpacing } from 'themes/common/constants'
import { FunnelChart } from 'react-funnel-pipeline'

import 'react-funnel-pipeline/dist/index.css'
import MainCard from 'components/ui/cards/MainCard'
import { useDispatch, useSelector } from 'react-redux'
import { analyticsActionCreator } from 'store/actions'
import i18next from 'i18next'
import useWindowDimensions from '../../hooks/useWindowDimensions'

const backgroundImages = [
  'linear-gradient(to bottom, #5196dc, #4189d5)',
  'linear-gradient(to bottom, #4189d5, #327dce)',
  'linear-gradient(to bottom, #327dce, #2572c8)',
  'linear-gradient(to bottom, #2572c8, #1565c0)',
]

const Chart = ({ locale, data, height }) =>
  useMemo(
    () => (
      <FunnelChart
        title={i18next.t('system_connection_statistics')}
        getRowNameStyle={() => ({
          fontSize: 12,
          position: 'relative',
          zIndex: 999,
        })}
        chartHeight={height}
        getRowStyle={({ backgroundImage }) => ({
          height: 90,
          backgroundImage,
        })}
        data={data?.map(({ label, count, backgroundImage }) => ({
          name: i18next.t(label.toLowerCase()),
          value: count,
          backgroundImage,
        }))}
      />
    ),
    [data, locale, height]
  )

const Analytics = () => {
  const dispatch = useDispatch()

  const { locale } = useSelector((state) => state.customization)
  const { deviceStats } = useSelector((state) => state.analytics)

  const chartData = deviceStats?.map((value, index) => ({
    ...value,
    backgroundImage: backgroundImages[index],
  }))

  const { height } = useWindowDimensions()

  useEffect(() => {
    dispatch(analyticsActionCreator.getDeviceStatsByStatus())
  }, [])

  return (
    <Grid container spacing={gridSpacing}>
      <Grid item xs={12} md={12} lg={12}>
        <MainCard
          content={false}
          sx={{
            paddingTop: 5,
            paddingBottom: 5,
            height: height > 700 ? `${height - 200}px` : `${height - 100}px`,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Grid item xs={12} lg={9} md={12}>
            {deviceStats && locale && (
              <Chart data={chartData} locale={locale} height={height} />
            )}
          </Grid>
        </MainCard>
      </Grid>
    </Grid>
  )
}

export default Analytics
