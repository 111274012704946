const ActionType = {
  GET_USERS: 'administration/get-users',
  GET_USERS_BY_TYPE: 'administration/get-users-by-type',
  GET_USER: 'administration/get-user',
  CREATE_USER: 'administration/post-user',
  UPDATE_USER: 'administration/put-user',
  UPDATE_PROFILE: 'administration/put-profile',
  CLEAR_USER_BY_ID: 'administration/clear-user-by-id',
}

export default ActionType
