import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import i18next from 'i18next'
import ActionType from './common'

const resetBranch = createAction(ActionType.RESET_BRANCH)

const getBranches = createAsyncThunk(
  ActionType.GET_BRANCHES,
  async (params, { extra: { services } }) => {
    const { data } = await services.branches.getBranches(params)

    return data
  }
)

const getBranch = createAsyncThunk(
  ActionType.GET_BRANCH,
  async (id, { extra: { services } }) => {
    const { data } = await services.branches.getBranch(id)
    let dealer = null

    if (data.dealerId) {
      const { data: dealerData } = await services.users.getUser(data.dealerId)
      dealer = dealerData
    }
    return { ...data, dealer }
  }
)

const createBranch = createAsyncThunk(
  ActionType.CREATE_BRANCH,
  async (payload, { extra: { services } }) => {
    await services.branches.createBranch(payload)

    services.notification.success(i18next.t('branch_created_successfully'))
  }
)

const updateBranch = createAsyncThunk(
  ActionType.UPDATE_BRANCH,
  async ({ id, payload }, { extra: { services } }) => {
    await services.branches.updateBranch(id, payload)

    services.notification.success(i18next.t('branch_updated_successfully'))
    return { ...payload, id }
  }
)

export { resetBranch, getBranches, getBranch, createBranch, updateBranch }
