import React from 'react'
import PropTypes from 'prop-types'

import { useTheme } from '@mui/material/styles'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from '@mui/material'

import { OrderType } from 'common/enums/enums'

import { getValueOrDash } from 'utils/utils'
import { Icon, IconDown, IconUp } from 'components/helpers/Helpers'
import { headCells } from './columns'

const EnhancedTableHead = ({ order, orderBy, onRequestSort, theme }) => {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property)
  }

  const mapOrderTypeToIcon = (sort) =>
    sort === OrderType.ASC ? IconUp : IconDown

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.sortable ? (
              <TableSortLabel
                active
                direction={orderBy === headCell.id ? order : 'asc'}
                style={{
                  color:
                    theme.palette.mode === 'dark'
                      ? theme.typography.menuCaption.color
                      : theme.palette.grey['900'],
                }}
                onClick={createSortHandler(headCell.id)}
                IconComponent={
                  orderBy === headCell.id ? mapOrderTypeToIcon(order) : Icon
                }
              >
                {headCell.label}
              </TableSortLabel>
            ) : (
              headCell.label
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

/* eslint-disable react/forbid-prop-types */
EnhancedTableHead.propTypes = {
  theme: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf([OrderType.ASC, OrderType.DESC, OrderType.NONE]),
  orderBy: PropTypes.string,
}

EnhancedTableHead.defaultProps = {
  order: OrderType.NONE,
  orderBy: null,
}

const RoleList = ({ rows, orderType, orderBy, handleRequestSort }) => {
  const theme = useTheme()

  return (
    <TableContainer>
      <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
        <EnhancedTableHead
          order={orderType}
          orderBy={orderBy}
          onRequestSort={(_, property) => {
            handleRequestSort(property)
          }}
          theme={theme}
        />
        <TableBody>
          {rows.map((row) => {
            const labelId = `enhanced-table-checkbox-${row.id}`

            return (
              <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                <TableCell
                  align="center"
                  component="th"
                  id={labelId}
                  scope="row"
                  onClick={() => {}}
                  sx={{ cursor: 'pointer' }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color:
                        theme.palette.mode === 'dark' ? 'grey.600' : 'grey.900',
                    }}
                  >
                    {getValueOrDash(row.id)}
                  </Typography>
                </TableCell>
                <TableCell
                  component="th"
                  id={labelId}
                  scope="row"
                  onClick={() => {}}
                  sx={{ cursor: 'pointer' }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color:
                        theme.palette.mode === 'dark' ? 'grey.600' : 'grey.900',
                    }}
                  >
                    {getValueOrDash(row.name)}
                  </Typography>
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

RoleList.propTypes = {
  rows: PropTypes.array.isRequired,
  orderType: PropTypes.string,
  orderBy: PropTypes.string,
  handleRequestSort: PropTypes.func.isRequired,
}

RoleList.defaultProps = {
  orderType: OrderType.NONE,
  orderBy: null,
}

export default RoleList
