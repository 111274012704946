import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import i18next from 'i18next'
import ActionType from './common'

const clearDeviceById = createAction(ActionType.CLEAR_DEVICE_BY_ID)

const clearDeviceChartsData = createAction(ActionType.CLEAR_DEVICE_CHART_DATA)

const clearDeviceSettings = createAction(ActionType.CLEAR_DEVICE_SETTINGS)

const clearDeviceAlarmSettings = createAction(
  ActionType.CLEAR_DEVICE_ALARM_SETTINGS
)

const clearDeviceThresholdsSettings = createAction(
  ActionType.CLEAR_DEVICE_THRESHOLDS_SETTINGS
)

const clearDeviceAlarmHistory = createAction(
  ActionType.CLEAR_DEVICE_ALARM_HISTORY
)

const setSettingData = createAction(ActionType.SET_SETTING_DATA, (data) => ({
  payload: data,
}))

const setLoading = createAction(ActionType.SET_LOADING, (payload) => ({
  payload,
}))

const getDevices = createAsyncThunk(
  ActionType.GET_DEVICES,
  async ({ branchId, params }, { extra: { services } }) => {
    const { data } = await services.branches.getBranchDevices(branchId, params)

    return data
  }
)

const getMembranes = createAsyncThunk(
  ActionType.GET_MEMBRANES,
  async (params, { extra: { services } }) => {
    const { data } = await services.devices.getMembranes(params)

    return data
  }
)

const getDevice = createAsyncThunk(
  ActionType.GET_DEVICE,
  async ({ id, params }, { extra: { services } }) => {
    const { data } = await services.devices.getDevice(id, params)
    const { data: model } = await services.models.getModel(data.modelId)

    let owner = null
    if (data.ownerId) {
      const { data: dataOwner } = await services.users.getUser(data.ownerId)
      owner = dataOwner
    }

    return { ...data, model, owner }
  }
)

const getDevicePhones = createAsyncThunk(
  ActionType.GET_DEVICE_PHONES,
  async (id, { extra: { services } }) => {
    const { data } = await services.devices.getDevicePhones(id)

    return data
  }
)

const getDeviceMembranes = createAsyncThunk(
  ActionType.GET_DEVICE_MEMBRANES,
  async (id, { extra: { services } }) => {
    const { data } = await services.devices.getDeviceMembranes(id)

    return data
  }
)

const getDeviceStats = createAsyncThunk(
  ActionType.GET_DEVICE_STATS,
  async ({ type, deviceId, ...params }, { extra: { services } }) => {
    const { data } = await services.devices.getDeviceStats(
      deviceId,
      type,
      params
    )

    return { data, type }
  }
)

const getDeviceWaterLogsLatest = createAsyncThunk(
  ActionType.GET_DEVICE_WATER_LOGS_LATEST,
  async ({ id, ...params }, { extra: { services } }) => {
    const { data } = await services.devices.getDeviceLatestWaterLogs(id, params)

    return data
  }
)

const getDeviceSettings = createAsyncThunk(
  ActionType.GET_DEVICE_SETTINGS,
  async ({ id, params }, { extra: { services } }) => {
    const { data } = await services.devices.getDeviceSettings(id, params)

    return data
  }
)

const getDeviceAlarmSettings = createAsyncThunk(
  ActionType.GET_DEVICE_ALARM_SETTINGS,
  async ({ id, params }, { extra: { services } }) => {
    const { data } = await services.devices.getDeviceAlarmSettings(id, params)

    return data
  }
)

const getDeviceThresholdsSettings = createAsyncThunk(
  ActionType.GET_DEVICE_THRESHOLDS_SETTINGS,
  async ({ id, params }, { extra: { services } }) => {
    const { data } = await services.devices.getDeviceThresholdsSettings(
      id,
      params
    )

    return data
  }
)

const getDeviceAlarmHistory = createAsyncThunk(
  ActionType.GET_DEVICE_ALARM_HISTORY,
  async ({ id, params }, { extra: { services } }) => {
    const { data } = await services.devices.getDeviceAlarmHistory(id, params)

    return data
  }
)

const postResetDeviceThresholdsSettings = createAsyncThunk(
  ActionType.POST_RESET_DEVICE_THRESHOLDS_SETTINGS,
  async ({ id, params }, { extra: { services } }) => {
    await services.devices.postResetDeviceThresholdsSettings(id, params)
  }
)

const getDeviceAnalogLogsLatest = createAsyncThunk(
  ActionType.GET_DEVICE_ANALOG_LOGS_LATEST,
  async (id, { extra: { services } }) => {
    const { data } = await services.devices.getDeviceLatestAnalogLogs(id)

    return data
  }
)

const getDeviceSettingValues = createAsyncThunk(
  ActionType.GET_DEVICE_SETTING_VALUES,
  async (name, { extra: { services } }) => {
    const { data } = await services.devices.getDeviceSettingValues(name)

    return data
  }
)

const createDevice = createAsyncThunk(
  ActionType.CREATE_DEVICE,
  async (payload, { extra: { services } }) => {
    await services.devices.createDevice(payload)

    services.notification.success(i18next.t('device_created_successfully'))
  }
)

const addDevice = createAsyncThunk(
  ActionType.ADD_DEVICE,
  async (payload, { extra: { services } }) => {
    await services.devices.tetherDevice(payload)

    services.notification.success(i18next.t('device_added_successfully'))
  }
)

const updateDeviceCreated = createAsyncThunk(
  ActionType.UPDATE_DEVICE_CREATED,
  async ({ id, payload }, { extra: { services } }) => {
    await services.devices.updateDevice(id, payload)

    services.notification.success(i18next.t('device_updated_successfully'))

    return { id, ...payload }
  }
)

const updateDeviceTethered = createAsyncThunk(
  ActionType.UPDATE_DEVICE_TETHERED,
  async ({ id, payload }, { extra: { services } }) => {
    await services.devices.updateTetheredDevice(id, payload)

    services.notification.success(i18next.t('device_updated_successfully'))

    return { id, ...payload }
  }
)
const updateThresholdAlarm = createAsyncThunk(
  ActionType.UPDATE_THRESHOLD_ALARM,
  async ({ id, payload }, { extra: { services } }) => {
    await services.devices.updateThresholdAlarm(id, payload)

    services.notification.success(i18next.t('device_updated_successfully'))

    return { id, ...payload }
  }
)

const updateDevicePhones = createAsyncThunk(
  ActionType.UPDATE_DEVICE_PHONES,
  async ({ id, payload }, { extra: { services } }) => {
    await services.devices.updatePhones(id, payload)

    services.notification.success(i18next.t('phones_updated_successfully'))

    return payload
  }
)

const updateDeviceMembranes = createAsyncThunk(
  ActionType.UPDATE_DEVICE_MEMBRANES,
  async ({ id, payload }, { extra: { services } }) => {
    await services.devices.updateMembranes(id, payload)
    const { data } = await services.devices.getDeviceMembranes(id)
    services.notification.success(i18next.t('membranes_updated_successfully'))

    return data
  }
)

const updateDeviceFirmware = createAsyncThunk(
  ActionType.UPDATE_DEVICE_FIRMWARE,
  async (id, { extra: { services } }) => {
    await services.devices.updateFirmware(id)

    services.notification.success(
      i18next.t('firmware_update_requested_successfully')
    )
  }
)

const updateDeviceSettings = createAsyncThunk(
  ActionType.UPDATE_DEVICE_SETTINGS,
  async (id, { extra: { services } }) => {
    await services.devices.updateDeviceSettings(id)

    services.notification.success(
      i18next.t('settings_update_requested_successfully')
    )
  }
)

const updateDeviceAlarmSetting = createAsyncThunk(
  ActionType.UPDATE_DEVICE_ALARM_SETTING,
  async ({ deviceId, alarmId, payload }, { extra: { services } }) => {
    await services.devices.updateDeviceAlarmSetting(deviceId, alarmId, payload)

    services.notification.success(
      i18next.t('alarm_logging_settings_updated_successfully')
    )

    return { alarmId, payload }
  }
)

const updateDeviceSetting = createAsyncThunk(
  ActionType.UPDATE_DEVICE_SETTING,
  async ({ id, name, value }, { extra: { services } }) => {
    await services.devices.updateDeviceSetting(id, name, value)

    services.notification.success(
      i18next.t('settings_update_requested_successfully')
    )
  }
)

export {
  getDevices,
  getMembranes,
  getDevice,
  getDevicePhones,
  getDeviceMembranes,
  getDeviceStats,
  getDeviceWaterLogsLatest,
  getDeviceSettings,
  getDeviceAlarmSettings,
  getDeviceThresholdsSettings,
  getDeviceAnalogLogsLatest,
  getDeviceSettingValues,
  getDeviceAlarmHistory,
  postResetDeviceThresholdsSettings,
  createDevice,
  addDevice,
  updateDeviceCreated,
  clearDeviceById,
  updateDeviceTethered,
  updateDeviceFirmware,
  updateDevicePhones,
  updateDeviceMembranes,
  updateDeviceSettings,
  updateDeviceAlarmSetting,
  updateDeviceSetting,
  clearDeviceChartsData,
  clearDeviceSettings,
  clearDeviceAlarmSettings,
  clearDeviceThresholdsSettings,
  clearDeviceAlarmHistory,
  setSettingData,
  setLoading,
  updateThresholdAlarm,
}
