import { createAsyncThunk } from '@reduxjs/toolkit'
import ActionType from './common'

const getRoles = createAsyncThunk(
  ActionType.GET_ROLES,
  async (params, { extra: { services } }) => {
    const { data } = await services.roles.getRoles(params)

    return data
  }
)

export { getRoles }
