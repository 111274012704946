import { createReducer, isAnyOf } from '@reduxjs/toolkit'

import { LoadingStatus } from 'common/enums/enums'
import {
  createBranch,
  getBranch,
  getBranches,
  resetBranch,
  updateBranch,
} from './actions'

const initialState = {
  branches: null,
  branchById: null,
  validationError: null,
  loading: LoadingStatus.IDLE,
}

/* eslint no-param-reassign: off */
const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getBranches.fulfilled, (state, action) => {
      state.branches = action.payload
      state.loading = LoadingStatus.SUCCEEDED
    })
    .addCase(getBranch.fulfilled, (state, action) => {
      state.branchById = action.payload
      state.loading = LoadingStatus.SUCCEEDED
    })
    .addCase(resetBranch, (state) => {
      state.branchById = null
    })
    .addCase(updateBranch.fulfilled, (state, action) => {
      state.loading = LoadingStatus.SUCCEEDED
      state.branches.data = state.branches?.data.map((item) => {
        if (item.id === action.payload.id) {
          return {
            ...item,
            name: action.payload.name,
          }
        }
        return item
      })
    })
    .addMatcher(isAnyOf(createBranch.fulfilled), (state) => {
      state.loading = LoadingStatus.SUCCEEDED
    })
    .addMatcher(
      isAnyOf(
        getBranches.pending,
        getBranch.pending,
        createBranch.pending,
        updateBranch.pending
      ),
      (state) => {
        state.loading = LoadingStatus.LOADING
      }
    )
    .addMatcher(
      isAnyOf(
        getBranches.rejected,
        getBranch.rejected,
        createBranch.rejected,
        updateBranch.rejected
      ),
      (state) => {
        state.loading = LoadingStatus.FAILED
      }
    )
})

export { reducer }
