import { get, patch, post, put } from 'helpers/http.helper'

const Users = (() => {
  const getUser = (id) => get(`/user-service/rodms/users/${id}`)

  const getUsers = (params) =>
    get('/user-service/rodms/users', {
      params,
    })

  const getUsersByType = (type, params) =>
    get(`/user-service/rodms/users/type/${type}`, {
      params,
    })

  const createUser = (payload) => post(`/user-service/rodms/users`, payload)

  const updateUser = (id, payload) =>
    put(`/user-service/rodms/users/${id}`, payload)

  const updateProfile = (id, payload) =>
    patch(`/user-service/rodms/users/${id}`, payload)

  return {
    getUser,
    getUsers,
    getUsersByType,
    createUser,
    updateUser,
    updateProfile,
  }
})()

export default Users
