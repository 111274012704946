import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useRoutes } from 'react-router-dom'

import { ThemeProvider } from '@mui/material/styles'
import { CssBaseline, StyledEngineProvider } from '@mui/material'

import themes from './themes'

import './App.css'
import NavigationScroll from './components/layout/NavigationScroll'
import routes from './routes/routes'
import Locales from './components/helpers/Locales'

const App = () => {
  const [appRoutes, setAppRoutes] = useState(routes(false))
  const customization = useSelector((state) => state.customization)
  const { authUser } = useSelector((state) => state.auth)

  useEffect(() => {
    setAppRoutes(routes(Boolean(authUser), authUser?.authorityIds))
  }, [authUser])

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={themes(customization)}>
        <CssBaseline />
        <Locales>
          <NavigationScroll>{useRoutes(appRoutes)}</NavigationScroll>
        </Locales>
      </ThemeProvider>
    </StyledEngineProvider>
  )
}

export default App
