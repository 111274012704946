import { get, post, put } from 'helpers/http.helper'

const Branches = (() => {
  const getBranches = (params) =>
    get('/rodms/branches', {
      params,
    })

  const getBranch = (id) => get(`/rodms/branches/${id}`)

  const getBranchDevices = (id, params) =>
    get(`/rodms/branches/${id}/devices`, {
      params,
    })

  const getBranchStats = (id) => get(`/rodms/branches/${id}/stats`)

  const getBranchDevicesByState = (id, state, params) =>
    get(`/rodms/branches/${id}/devices/${state}`, {
      params,
    })

  const createBranch = (payload) => post(`/rodms/branches`, payload)

  const updateBranch = (id, payload) => put(`/rodms/branches/${id}`, payload)

  return {
    getBranches,
    getBranch,
    getBranchStats,
    getBranchDevicesByState,
    getBranchDevices,
    createBranch,
    updateBranch,
  }
})()

export default Branches
