import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { switchOrderType } from 'utils/utils'
import { OrderType } from 'common/enums/app/order-type.enum'

const useSort = (orderTypeProp = OrderType.NONE, orderByProp = null) => {
  const dispatch = useDispatch()

  const [orderType, setOrderType] = useState(orderTypeProp)
  const [orderBy, setOrderBy] = useState(orderByProp)

  const handleRequestSort = (property, action) => {
    const { orderBy: newOrderBy, orderType: newOrderType } = switchOrderType(
      orderBy,
      property,
      orderType
    )
    setOrderType(newOrderType)
    setOrderBy(newOrderBy)
    dispatch(action(newOrderBy, newOrderType))
  }

  return {
    orderBy,
    orderType,
    setOrderBy,
    setOrderType,
    handleRequestSort,
  }
}

export default useSort
