import { combineReducers, configureStore } from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage'
import { persistReducer } from 'redux-persist'
import { encryptTransform } from 'redux-persist-transform-encrypt'

import * as services from 'services/services'
import {
  analyticsReducer,
  authReducer,
  branchesReducer,
  calculationsReducer,
  commonReducer,
  customizationReducer,
  dashboardReducer,
  devicesReducer,
  headerReducer,
  modelsReducer,
  rolesReducer,
  usersReducer,
  frequentlyAskedQuestionsReducer,
} from './root-reducer'
import { handleError } from './middlewares/handle-error/handle-error.middleware'

const rootReducer = combineReducers({
  auth: authReducer,
  customization: customizationReducer,
  roles: rolesReducer,
  users: usersReducer,
  branches: branchesReducer,
  header: headerReducer,
  devices: devicesReducer,
  common: commonReducer,
  dashboard: dashboardReducer,
  analytics: analyticsReducer,
  models: modelsReducer,
  calculations: calculationsReducer,
  frequentlyAskedQuestions: frequentlyAskedQuestionsReducer,
})

const persistConfig = {
  key: 'root',
  debug: true,
  storage,
  transforms: [
    encryptTransform({
      secretKey: 'mY_s3cr3T_KeY-ult@Pow3R',
      onError: (error) => {
        console.error(error)
      },
    }),
  ],
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: {
        extraArgument: { services },
      },
      serializableCheck: false,
    }).concat(handleError),
})

export { store }
