import React from 'react'
import { IconDashboard, IconDeviceAnalytics } from '@tabler/icons'
import { FormattedMessage } from 'react-intl'

const icons = { IconDashboard, IconDeviceAnalytics }

export const defaultRequests = [
  {
    httpMethod: 'GET',
    pattern: '/branches/*/stats',
  },
  {
    httpMethod: 'GET',
    pattern: '/branches/*/devices',
  },
  {
    httpMethod: 'GET',
    pattern: '/branches/*/devices/*',
  },
]

export const analyticsRequests = [
  {
    httpMethod: 'GET',
    pattern: '/devices/stats/by-status',
  },
]

const dashboard = {
  id: 'dashboard',
  title: '',
  type: 'group',
  children: [
    {
      id: 'default',
      title: <FormattedMessage id="dashboard" defaultMessage="Dashboard" />,
      type: 'item',
      url: '/app/dashboard',
      icon: icons.IconDashboard,
      breadcrumbs: false,
      requests: defaultRequests,
    },
    {
      id: 'analytics',
      title: <FormattedMessage id="analytics" defaultMessage="Analytics" />,
      type: 'item',
      url: '/app/analytics',
      icon: icons.IconDeviceAnalytics,
      breadcrumbs: false,
      requests: analyticsRequests,
    },
  ],
}

export default dashboard
