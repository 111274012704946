import { createAsyncThunk } from '@reduxjs/toolkit'
import ActionType from './common'

const getModels = createAsyncThunk(
  ActionType.GET_MODELS,
  async (params, { extra: { services } }) => {
    const { data } = await services.models.getModels(params)

    return data
  }
)

export { getModels }
