import { HttpMethod } from '../enums/http/http'
import { PermissionEnum } from '../enums/app/app'

const AuthorizeExchange = {
  exchange: {},

  _authorizeExchange: (exchange, pattern) => ({
    hasAnyAuthority(...access) {
      // eslint-disable-next-line no-param-reassign
      exchange[pattern] = access
      return AuthorizeExchange
    },
    permitAll() {
      // eslint-disable-next-line no-param-reassign
      exchange[pattern] = ['*']
      return AuthorizeExchange
    },
  }),

  pathMatcher(httpMethod, pattern) {
    this.exchange = {
      ...this.exchange,
      [`${pattern}-${httpMethod}`]: [],
    }
    return this._authorizeExchange(this.exchange, `${pattern}-${httpMethod}`)
  },

  get(httpMethod, pattern) {
    return [...(this.exchange[`${pattern}-${httpMethod}`] || [])]
  },

  build() {
    return {
      exchange: this.exchange,
      get: this.get,
    }
  },
}

const authorizeExchange = AuthorizeExchange.pathMatcher(
  HttpMethod.GET,
  '/users/*'
)
  .permitAll()
  .pathMatcher(HttpMethod.POST, '/users')
  .hasAnyAuthority(
    PermissionEnum.FULL_ACCESS,
    PermissionEnum.POST_FULL_ACCESS,
    PermissionEnum.FULL_ACCESS_USERS,
    PermissionEnum.POST_FULL_ACCESS_USERS
  )

  .pathMatcher(HttpMethod.PUT, '/users/*')
  .hasAnyAuthority(
    PermissionEnum.FULL_ACCESS,
    PermissionEnum.PUT_FULL_ACCESS,
    PermissionEnum.FULL_ACCESS_USERS,
    PermissionEnum.PUT_FULL_ACCESS_USERS
  )
  .pathMatcher(HttpMethod.GET, '/users')
  .hasAnyAuthority(
    PermissionEnum.FULL_ACCESS,
    PermissionEnum.GET_FULL_ACCESS,
    PermissionEnum.FULL_ACCESS_USERS,
    PermissionEnum.GET_FULL_ACCESS_USERS
  )
  .pathMatcher(HttpMethod.GET, '/roles')
  .hasAnyAuthority(
    PermissionEnum.FULL_ACCESS,
    PermissionEnum.GET_FULL_ACCESS,
    PermissionEnum.FULL_ACCESS_ROLES,
    PermissionEnum.GET_FULL_ACCESS_ROLES
  )
  .pathMatcher(HttpMethod.GET, '/branches/*/stats')
  .hasAnyAuthority(
    PermissionEnum.FULL_ACCESS,
    PermissionEnum.GET_FULL_ACCESS,
    PermissionEnum.FULL_ACCESS_STATISTICS,
    PermissionEnum.GET_FULL_ACCESS_STATISTICS,
    PermissionEnum.FULL_ACCESS_BRANCHES,
    PermissionEnum.GET_FULL_ACCESS_BRANCHES
  )
  .pathMatcher(HttpMethod.GET, '/branches')
  .hasAnyAuthority(
    PermissionEnum.FULL_ACCESS,
    PermissionEnum.GET_FULL_ACCESS,
    PermissionEnum.FULL_ACCESS_BRANCHES,
    PermissionEnum.GET_FULL_ACCESS_BRANCHES
  )
  .pathMatcher(HttpMethod.GET, '/branches/*')
  .hasAnyAuthority(
    PermissionEnum.FULL_ACCESS,
    PermissionEnum.GET_FULL_ACCESS,
    PermissionEnum.FULL_ACCESS_BRANCHES,
    PermissionEnum.GET_FULL_ACCESS_BRANCHES,
    PermissionEnum.GET_BRANCH
  )
  .pathMatcher(HttpMethod.POST, '/branches')
  .hasAnyAuthority(
    PermissionEnum.FULL_ACCESS,
    PermissionEnum.FULL_ACCESS_BRANCHES,
    PermissionEnum.POST_FULL_ACCESS_BRANCHES
  )
  .pathMatcher(HttpMethod.PUT, '/branches/*')
  .hasAnyAuthority(
    PermissionEnum.FULL_ACCESS,
    PermissionEnum.FULL_ACCESS_BRANCHES,
    PermissionEnum.PUT_FULL_ACCESS_BRANCHES
  )
  .pathMatcher(HttpMethod.GET, '/branches/*/devices')
  .hasAnyAuthority(
    PermissionEnum.FULL_ACCESS,
    PermissionEnum.GET_FULL_ACCESS,
    PermissionEnum.FULL_ACCESS_STATISTICS,
    PermissionEnum.FULL_ACCESS_BRANCHES,
    PermissionEnum.GET_FULL_ACCESS_BRANCHES,
    PermissionEnum.GET_FULL_ACCESS_DEVICES,
    PermissionEnum.GET_BRANCH_DEVICES
  )
  .pathMatcher(HttpMethod.GET, '/branches/*/devices/*')
  .hasAnyAuthority(
    PermissionEnum.FULL_ACCESS,
    PermissionEnum.GET_FULL_ACCESS,
    PermissionEnum.FULL_ACCESS_STATISTICS,
    PermissionEnum.FULL_ACCESS_BRANCHES,
    PermissionEnum.GET_FULL_ACCESS_BRANCHES,
    PermissionEnum.GET_FULL_ACCESS_DEVICES,
    PermissionEnum.GET_BRANCH_DEVICES
  )
  .pathMatcher(HttpMethod.POST, '/devices')
  .hasAnyAuthority(
    PermissionEnum.FULL_ACCESS,

    PermissionEnum.POST_FULL_ACCESS,
    PermissionEnum.POST_FULL_ACCESS_DEVICES,
    PermissionEnum.POST_DEVICE
  )

  .pathMatcher(HttpMethod.GET, '/devices/*')
  .hasAnyAuthority(
    PermissionEnum.FULL_ACCESS,

    PermissionEnum.GET_FULL_ACCESS,

    PermissionEnum.GET_FULL_ACCESS_DEVICES,
    PermissionEnum.GET_DEVICE
  )

  .pathMatcher(HttpMethod.PUT, '/devices/*')
  .hasAnyAuthority(
    PermissionEnum.FULL_ACCESS,

    PermissionEnum.PUT_FULL_ACCESS,
    PermissionEnum.PUT_FULL_ACCESS_DEVICES,
    PermissionEnum.PUT_DEVICE
  )
  .pathMatcher(HttpMethod.GET, '/devices/*/phones')
  .hasAnyAuthority(
    PermissionEnum.FULL_ACCESS,

    PermissionEnum.GET_FULL_ACCESS,

    PermissionEnum.GET_FULL_ACCESS_DEVICES
  )
  .pathMatcher(HttpMethod.PUT, '/devices/*/phones')
  .hasAnyAuthority(
    PermissionEnum.FULL_ACCESS,

    PermissionEnum.PUT_FULL_ACCESS,

    PermissionEnum.PUT_FULL_ACCESS_DEVICES
  )
  .pathMatcher(HttpMethod.GET, '/devices/stats/by-status')
  .hasAnyAuthority(PermissionEnum.FULL_ACCESS)
  .pathMatcher(HttpMethod.POST, '/devices/tether')
  .hasAnyAuthority(
    PermissionEnum.FULL_ACCESS,
    PermissionEnum.POST_FULL_ACCESS,
    PermissionEnum.POST_FULL_ACCESS_DEVICES,
    PermissionEnum.POST_TETHER_DEVICE
  )
  .pathMatcher(HttpMethod.PUT, '/devices/tether/*')
  .hasAnyAuthority(
    PermissionEnum.FULL_ACCESS,
    PermissionEnum.PUT_FULL_ACCESS,
    PermissionEnum.PUT_FULL_ACCESS_DEVICES,
    PermissionEnum.PUT_TETHER_DEVICE
  )
  .pathMatcher(HttpMethod.GET, '/devices/*/membranes')
  .hasAnyAuthority(
    PermissionEnum.FULL_ACCESS,
    PermissionEnum.GET_FULL_ACCESS,
    PermissionEnum.GET_FULL_ACCESS_DEVICES,
    PermissionEnum.GET_FULL_ACCESS_MEMBRANES
  )
  .pathMatcher(HttpMethod.PUT, '/devices/*/membranes')
  .hasAnyAuthority(
    PermissionEnum.FULL_ACCESS,
    PermissionEnum.PUT_FULL_ACCESS,
    PermissionEnum.PUT_FULL_ACCESS_DEVICES,
    PermissionEnum.PUT_FULL_ACCESS_MEMBRANES
  )
  .pathMatcher(HttpMethod.GET, '/statistics/devices/*/*')
  .hasAnyAuthority(
    PermissionEnum.FULL_ACCESS,
    PermissionEnum.GET_FULL_ACCESS,
    PermissionEnum.FULL_ACCESS_STATISTICS,
    PermissionEnum.GET_FULL_ACCESS_STATISTICS,
    PermissionEnum.GET_DEVICE_STATISTICS
  )
  .pathMatcher(HttpMethod.GET, '/statistics/branches/*/*')
  .hasAnyAuthority(
    PermissionEnum.FULL_ACCESS,
    PermissionEnum.GET_FULL_ACCESS,
    PermissionEnum.FULL_ACCESS_STATISTICS,
    PermissionEnum.GET_FULL_ACCESS_STATISTICS
  )
  .pathMatcher(HttpMethod.PUT, '/devices/*/firmware-update')
  .hasAnyAuthority(
    PermissionEnum.FULL_ACCESS,
    PermissionEnum.PUT_FULL_ACCESS,
    PermissionEnum.PUT_FULL_ACCESS_DEVICES,
    PermissionEnum.PUT_FULL_ACCESS_DEVICE_ACTIONS
  )
  .pathMatcher(HttpMethod.PUT, '/devices/*/reset-alarm')
  .hasAnyAuthority(
    PermissionEnum.FULL_ACCESS,
    PermissionEnum.PUT_FULL_ACCESS,
    PermissionEnum.PUT_FULL_ACCESS_DEVICES,
    PermissionEnum.PUT_FULL_ACCESS_DEVICE_ACTIONS
  )
  .pathMatcher(HttpMethod.PUT, '/devices/*/request-settings')
  .hasAnyAuthority(
    PermissionEnum.FULL_ACCESS,
    PermissionEnum.PUT_FULL_ACCESS,
    PermissionEnum.PUT_FULL_ACCESS_DEVICES,
    PermissionEnum.PUT_FULL_ACCESS_DEVICE_ACTIONS
  )
  .pathMatcher(HttpMethod.GET, '/devices/*/alarms/latest')
  .hasAnyAuthority(
    PermissionEnum.FULL_ACCESS,
    PermissionEnum.GET_FULL_ACCESS,

    PermissionEnum.GET_FULL_ACCESS_DEVICE_LOGS
  )
  .pathMatcher(HttpMethod.GET, '/devices/*/logs/analog/latest')
  .hasAnyAuthority(
    PermissionEnum.FULL_ACCESS,
    PermissionEnum.GET_FULL_ACCESS,

    PermissionEnum.GET_FULL_ACCESS_DEVICE_LOGS
  )
  .pathMatcher(HttpMethod.GET, '/devices/*/logs/water/latest')
  .hasAnyAuthority(
    PermissionEnum.FULL_ACCESS,
    PermissionEnum.GET_FULL_ACCESS,

    PermissionEnum.GET_FULL_ACCESS_DEVICE_LOGS
  )
  .pathMatcher(HttpMethod.GET, '/devices/*/alarms/logs')
  .hasAnyAuthority(
    PermissionEnum.FULL_ACCESS,
    PermissionEnum.GET_FULL_ACCESS,

    PermissionEnum.GET_FULL_ACCESS_DEVICE_LOGS
  )
  .pathMatcher(HttpMethod.POST, '/models')
  .hasAnyAuthority(
    PermissionEnum.FULL_ACCESS,
    PermissionEnum.POST_FULL_ACCESS,
    PermissionEnum.FULL_ACCESS_DEVICE_MODELS,
    PermissionEnum.POST_FULL_ACCESS_DEVICE_MODELS
  )
  .pathMatcher(HttpMethod.GET, '/models')
  .hasAnyAuthority(
    PermissionEnum.FULL_ACCESS,
    PermissionEnum.GET_FULL_ACCESS,
    PermissionEnum.FULL_ACCESS_DEVICE_MODELS,
    PermissionEnum.GET_FULL_ACCESS_DEVICE_MODELS
  )
  .pathMatcher(HttpMethod.GET, '/models/*')
  .hasAnyAuthority(
    PermissionEnum.FULL_ACCESS,
    PermissionEnum.GET_FULL_ACCESS,
    PermissionEnum.FULL_ACCESS_DEVICE_MODELS,
    PermissionEnum.GET_FULL_ACCESS_DEVICE_MODELS
  )
  .pathMatcher(HttpMethod.PUT, '/models/*')
  .hasAnyAuthority(
    PermissionEnum.FULL_ACCESS,
    PermissionEnum.PUT_FULL_ACCESS,
    PermissionEnum.FULL_ACCESS_DEVICE_MODELS,
    PermissionEnum.PUT_FULL_ACCESS_DEVICE_MODELS
  )
  .pathMatcher(HttpMethod.GET, '/devices/*/settings')
  .hasAnyAuthority(
    PermissionEnum.FULL_ACCESS,
    PermissionEnum.GET_FULL_ACCESS,
    PermissionEnum.FULL_ACCESS_DEVICE_SETTINGS,
    PermissionEnum.POST_FULL_ACCESS_DEVICE_SETTINGS
  )
  .pathMatcher(HttpMethod.PUT, '/devices/*/settings/*')
  .hasAnyAuthority(
    PermissionEnum.FULL_ACCESS,
    PermissionEnum.PUT_FULL_ACCESS,
    PermissionEnum.FULL_ACCESS_DEVICE_SETTINGS,
    PermissionEnum.PUT_FULL_ACCESS_DEVICE_SETTINGS
  )
  .pathMatcher(HttpMethod.GET, '/devices/settings/*/values')
  .hasAnyAuthority(
    PermissionEnum.FULL_ACCESS,
    PermissionEnum.GET_FULL_ACCESS,
    PermissionEnum.FULL_ACCESS_DEVICE_SETTINGS,
    PermissionEnum.GET_FULL_ACCESS_DEVICE_SETTINGS
  )
  .pathMatcher(HttpMethod.GET, '/devices/monitoring-settings')
  .hasAnyAuthority(
    PermissionEnum.FULL_ACCESS,
    PermissionEnum.GET_FULL_ACCESS,
    PermissionEnum.FULL_ACCESS_DEVICE_SETTINGS,
    PermissionEnum.GET_FULL_ACCESS_DEVICE_SETTINGS
  )
  .pathMatcher(HttpMethod.GET, '/devices/*/monitoring-settings')
  .hasAnyAuthority(
    PermissionEnum.FULL_ACCESS,
    PermissionEnum.GET_FULL_ACCESS,
    PermissionEnum.FULL_ACCESS_DEVICE_SETTINGS,
    PermissionEnum.GET_FULL_ACCESS_DEVICE_SETTINGS
  )
  .pathMatcher(HttpMethod.PUT, '/devices/*/monitoring-settings')
  .hasAnyAuthority(
    PermissionEnum.FULL_ACCESS,
    PermissionEnum.PUT_FULL_ACCESS,
    PermissionEnum.FULL_ACCESS_DEVICE_SETTINGS,
    PermissionEnum.PUT_FULL_ACCESS_DEVICE_SETTINGS
  )
  .pathMatcher(HttpMethod.POST, '/devices/*/monitoring-settings/defaults')
  .hasAnyAuthority(
    PermissionEnum.FULL_ACCESS,
    PermissionEnum.POST_FULL_ACCESS,
    PermissionEnum.FULL_ACCESS_DEVICE_SETTINGS,
    PermissionEnum.POST_FULL_ACCESS_DEVICE_SETTINGS
  )
  .pathMatcher(HttpMethod.GET, '/devices/*/settings')
  .hasAnyAuthority(
    PermissionEnum.FULL_ACCESS,
    PermissionEnum.GET_FULL_ACCESS,
    PermissionEnum.FULL_ACCESS_DEVICE_SETTINGS,
    PermissionEnum.GET_FULL_ACCESS_DEVICE_SETTINGS
  )
  .pathMatcher(HttpMethod.PUT, '/devices/*/request-settings')
  .hasAnyAuthority(
    PermissionEnum.FULL_ACCESS,
    PermissionEnum.PUT_FULL_ACCESS,
    PermissionEnum.PUT_FULL_ACCESS_DEVICES,
    PermissionEnum.PUT_FULL_ACCESS_DEVICE_ACTIONS
  )
  .pathMatcher(HttpMethod.GET, '/devices/*/alarm/settings')
  .hasAnyAuthority(
    PermissionEnum.FULL_ACCESS,
    PermissionEnum.GET_FULL_ACCESS,
    PermissionEnum.FULL_ACCESS_DEVICE_SETTINGS,
    PermissionEnum.GET_FULL_ACCESS_DEVICE_SETTINGS,
    PermissionEnum.GET_DEVICE_ALARM_SETTINGS
  )
  .pathMatcher(HttpMethod.PUT, '/devices/*/alarm/*/settings')
  .hasAnyAuthority(
    PermissionEnum.FULL_ACCESS,
    PermissionEnum.PUT_FULL_ACCESS,
    PermissionEnum.FULL_ACCESS_DEVICE_SETTINGS,
    PermissionEnum.PUT_FULL_ACCESS_DEVICE_SETTINGS,
    PermissionEnum.PUT_DEVICE_ALARM_SETTINGS
  )
  .pathMatcher(HttpMethod.GET, '/membranes')
  .hasAnyAuthority(
    PermissionEnum.FULL_ACCESS,
    PermissionEnum.GET_FULL_ACCESS,
    PermissionEnum.FULL_ACCESS_MEMBRANES,
    PermissionEnum.GET_FULL_ACCESS_MEMBRANES
  )
  .pathMatcher(HttpMethod.GET, '/membranes/*')
  .hasAnyAuthority(
    PermissionEnum.FULL_ACCESS,
    PermissionEnum.GET_FULL_ACCESS,
    PermissionEnum.FULL_ACCESS_MEMBRANES,
    PermissionEnum.GET_FULL_ACCESS_MEMBRANES
  )
  .pathMatcher(HttpMethod.POST, '/membranes')
  .hasAnyAuthority(
    PermissionEnum.FULL_ACCESS,
    PermissionEnum.POST_FULL_ACCESS,
    PermissionEnum.FULL_ACCESS_MEMBRANES,
    PermissionEnum.POST_FULL_ACCESS_MEMBRANES
  )
  .pathMatcher(HttpMethod.PUT, '/membranes/*')
  .hasAnyAuthority(
    PermissionEnum.FULL_ACCESS,
    PermissionEnum.PUT_FULL_ACCESS,
    PermissionEnum.FULL_ACCESS_MEMBRANES,
    PermissionEnum.PUT_FULL_ACCESS_MEMBRANES
  )
  .pathMatcher(HttpMethod.GET, '/devices/*/monitoring-settings')
  .hasAnyAuthority(
    PermissionEnum.FULL_ACCESS,
    PermissionEnum.GET_FULL_ACCESS,
    PermissionEnum.FULL_ACCESS_DEVICE_SETTINGS,
    PermissionEnum.GET_FULL_ACCESS_DEVICE_SETTINGS
  )
  .pathMatcher(HttpMethod.GET, '/devices/*/alarms/logs')
  .hasAnyAuthority(
    PermissionEnum.FULL_ACCESS,
    PermissionEnum.GET_FULL_ACCESS,
    PermissionEnum.GET_FULL_ACCESS_DEVICE_LOGS
  )
  .pathMatcher(HttpMethod.GET, '/calculations/*')
  .hasAnyAuthority(
    PermissionEnum.FULL_ACCESS,
    PermissionEnum.GET_FULL_ACCESS,
    PermissionEnum.FULL_ACCESS_CALCULATIONS,
    PermissionEnum.GET_FULL_ACCESS_CALCULATIONS
  )
  .pathMatcher(HttpMethod.POST, '/calculations')
  .hasAnyAuthority(
    PermissionEnum.FULL_ACCESS,
    PermissionEnum.GET_FULL_ACCESS,
    PermissionEnum.FULL_ACCESS_CALCULATIONS,
    PermissionEnum.POST_FULL_ACCESS_CALCULATIONS
  )
  .pathMatcher(HttpMethod.GET, '/faq')
  .permitAll()
  .build()

export const hasAccess = (httpMethod, pattern, permissions = []) =>
  permissions.some(
    (element) =>
      authorizeExchange.get(httpMethod, pattern)?.indexOf(element) !== -1
  ) || authorizeExchange.get(httpMethod, pattern)[0] === '*'
