import React from 'react'
import { FormattedMessage } from 'react-intl'

const headCells = [
  {
    id: 'id',
    numeric: true,
    label: <FormattedMessage id="id" defaultMessage="ID" />,
    align: 'center',
    sortable: true,
  },
  {
    id: 'name',
    numeric: false,
    label: <FormattedMessage id="name" defaultMessage="Name" />,
    align: 'left',
    sortable: true,
  },
]

export { headCells }
