import React from 'react'
import PropTypes from 'prop-types'
import { Button, Menu, MenuItem } from '@mui/material'
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'
import { FormattedMessage } from 'react-intl'
import { useTheme } from '@mui/styles'

const PageSizer = ({
  anchorEl,
  pageSize,
  handleClick,
  handleClose,
  sizeOptions,
}) => {
  const theme = useTheme()

  return (
    <>
      <Button
        size="large"
        sx={{ color: theme.palette.grey[900] }}
        color="secondary"
        endIcon={<ExpandMoreRoundedIcon />}
        onClick={handleClick}
      >
        {pageSize} <FormattedMessage id="rows" defaultMessage="Rows" />
      </Button>
      <Menu
        id="menu-user-list-style1"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        variant="selectedMenu"
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        {sizeOptions.map((option) => (
          <MenuItem key={option} onClick={() => handleClose(option)}>
            {' '}
            {option} <FormattedMessage id="rows" defaultMessage="Rows" />
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

/* eslint-disable react/forbid-prop-types */
PageSizer.propTypes = {
  anchorEl: PropTypes.object,
  pageSize: PropTypes.number.isRequired,
  handleClick: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  sizeOptions: PropTypes.array.isRequired,
}

PageSizer.defaultProps = {
  anchorEl: null,
}

export default PageSizer
