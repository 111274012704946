import { createReducer, isAnyOf } from '@reduxjs/toolkit'

import { LoadingStatus } from 'common/enums/enums'
import { getFrequentlyAskedQuestions } from './actions'

const initialState = {
  frequentlyAskedQuestions: null,
  loading: LoadingStatus.IDLE,
}

/* eslint no-param-reassign: off */
const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getFrequentlyAskedQuestions.fulfilled, (state, action) => {
      state.frequentlyAskedQuestions = action.payload
      state.loading = LoadingStatus.SUCCEEDED
    })
    .addMatcher(isAnyOf(getFrequentlyAskedQuestions.pending), (state) => {
      state.loading = LoadingStatus.LOADING
    })
})

export { reducer }
