import { get } from 'helpers/http.helper'

const Models = (() => {
  const getModels = (params) =>
    get(`/rodms/models`, {
      params,
    })

  const getModel = (id) => get(`/rodms/models/${id}`)

  return {
    getModels,
    getModel,
  }
})()

export default Models
