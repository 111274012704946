import { createReducer, isAnyOf } from '@reduxjs/toolkit'
import { LoadingStatus } from 'common/enums/enums'
import { getBranchDevicesByState, getBranchStats } from './actions'

const initialState = {
  branchStats: null,
  loading: LoadingStatus.IDLE,
  branchDevices: null,
}

/* eslint no-param-reassign: off */
const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getBranchStats.fulfilled, (state, action) => {
      state.branchStats = action.payload
      state.loading = LoadingStatus.SUCCEEDED
    })
    .addCase(getBranchDevicesByState.fulfilled, (state, action) => {
      state.branchDevices = action.payload
    })
    .addMatcher(
      isAnyOf(getBranchStats.rejected, getBranchDevicesByState.rejected),
      (state) => {
        state.loading = LoadingStatus.FAILED
      }
    )
    .addMatcher(
      isAnyOf(getBranchStats.pending, getBranchDevicesByState.pending),
      (state) => {
        state.loading = LoadingStatus.LOADING
      }
    )
})

export { reducer }
