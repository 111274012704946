import { createReducer, isAnyOf } from '@reduxjs/toolkit'

import { LoadingStatus } from 'common/enums/enums'
import {
  addDevice,
  clearDeviceById,
  clearDeviceChartsData,
  clearDeviceSettings,
  clearDeviceAlarmSettings,
  createDevice,
  getDevice,
  getDeviceMembranes,
  getDevicePhones,
  getDevices,
  getDeviceSettings,
  getDeviceAlarmSettings,
  getDeviceStats,
  getDeviceWaterLogsLatest,
  getDeviceAnalogLogsLatest,
  getMembranes,
  getDeviceSettingValues,
  getDeviceAlarmHistory,
  updateDeviceCreated,
  updateDeviceMembranes,
  updateDevicePhones,
  updateDeviceTethered,
  updateDeviceAlarmSetting,
  setSettingData,
  getDeviceThresholdsSettings,
  postResetDeviceThresholdsSettings,
  updateThresholdAlarm,
  setLoading,
} from './actions'

const initialState = {
  devices: {},
  membranes: {},
  deviceById: null,
  devicePhones: {},
  deviceMembranes: {},
  deviceSettings: {},
  deviceSettingValues: {},
  settingData: {},
  deviceAlarmSettings: {},
  deviceThresholdsSettings: {},
  deviceAlarmHistory: {},
  validationError: null,
  loading: LoadingStatus.IDLE,
  water: {},
  waterLogs: null,
  analogLogs: null,
  temperature_in: {},
  temperature_out: {},
  tds_in: {},
  tds_out: {},
  flowrate: {},
  rejection: {},
}

/* eslint no-param-reassign: off */
const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getDevices.fulfilled, (state, action) => {
      state.devices = action.payload
      state.loading = LoadingStatus.SUCCEEDED
    })
    .addCase(getMembranes.fulfilled, (state, action) => {
      state.membranes = action.payload
      state.loading = LoadingStatus.SUCCEEDED
    })
    .addCase(getDeviceStats.fulfilled, (state, action) => {
      state[action.payload.type] = action.payload.data
      state.loading = LoadingStatus.SUCCEEDED
    })
    .addCase(getDeviceWaterLogsLatest.fulfilled, (state, action) => {
      state.waterLogs = action.payload
      state.loading = LoadingStatus.SUCCEEDED
    })
    .addCase(getDeviceAnalogLogsLatest.fulfilled, (state, action) => {
      state.analogLogs = action.payload
      state.loading = LoadingStatus.SUCCEEDED
    })
    .addCase(getDevice.fulfilled, (state, action) => {
      state.deviceById = action.payload
      state.loading = LoadingStatus.SUCCEEDED
    })
    .addCase(getDevicePhones.fulfilled, (state, action) => {
      state.devicePhones = action.payload
      state.loading = LoadingStatus.SUCCEEDED
    })
    .addCase(getDeviceMembranes.fulfilled, (state, action) => {
      state.deviceMembranes = action.payload
      state.loading = LoadingStatus.SUCCEEDED
    })
    .addCase(getDeviceSettings.fulfilled, (state, action) => {
      state.deviceSettings = action.payload
      state.loading = LoadingStatus.SUCCEEDED
    })
    .addCase(getDeviceAlarmSettings.fulfilled, (state, action) => {
      state.deviceAlarmSettings = action.payload
      state.loading = LoadingStatus.SUCCEEDED
    })
    .addCase(getDeviceThresholdsSettings.fulfilled, (state, action) => {
      state.deviceThresholdsSettings = action.payload
      state.loading = LoadingStatus.SUCCEEDED
    })
    .addCase(getDeviceAlarmHistory.fulfilled, (state, action) => {
      state.deviceAlarmHistory = action.payload
      state.loading = LoadingStatus.SUCCEEDED
    })

    .addCase(postResetDeviceThresholdsSettings.fulfilled, (state) => {
      state.loading = LoadingStatus.SUCCEEDED
    })

    .addCase(getDeviceSettingValues.fulfilled, (state, action) => {
      state.deviceSettingValues = action.payload
      state.loading = LoadingStatus.SUCCEEDED
    })
    .addCase(setSettingData, (state, action) => {
      state.settingData = action.payload
    })
    .addCase(setLoading, (state, action) => {
      state.loading = action.payload
    })
    .addCase(clearDeviceById, (state) => {
      state.deviceById = null
    })
    .addCase(clearDeviceChartsData, (state) => {
      state.water = {}
      state.temperature_in = {}
      state.temperature_out = {}
      state.tds_in = {}
      state.tds_out = {}
      state.flowrate = {}
      state.rejection = {}
    })
    .addCase(clearDeviceSettings, (state) => {
      state.deviceSettings = {}
    })
    .addCase(clearDeviceAlarmSettings, (state) => {
      state.deviceAlarmSettings = {}
    })
    .addCase(updateThresholdAlarm, (state) => {
      state.loading = LoadingStatus.SUCCEEDED
    })
    .addMatcher(
      isAnyOf(updateDeviceCreated.fulfilled, updateDeviceTethered.fulfilled),
      (state, action) => {
        state.loading = LoadingStatus.SUCCEEDED
        state.devices.data = state.devices?.data.map((item) => {
          if (item.id === action.payload.id) {
            return {
              ...item,
              ...action.payload,
            }
          }
          return item
        })
        state.deviceById = {
          ...state.deviceById,
          ...action.payload,
        }
      }
    )
    .addMatcher(isAnyOf(updateDevicePhones.fulfilled), (state, action) => {
      state.loading = LoadingStatus.SUCCEEDED
      state.devicePhones = {
        ...action.payload,
      }
    })
    .addMatcher(isAnyOf(updateDeviceMembranes.fulfilled), (state, action) => {
      state.loading = LoadingStatus.SUCCEEDED
      state.deviceMembranes = {
        ...action.payload,
      }
    })
    .addMatcher(
      isAnyOf(createDevice.fulfilled, addDevice.fulfilled),
      (state) => {
        state.loading = LoadingStatus.SUCCEEDED
      }
    )
    .addMatcher(
      isAnyOf(
        getDevices.pending,
        getMembranes.pending,
        createDevice.pending,
        addDevice.pending,
        getDevice.pending,
        getDevicePhones.pending,
        getDeviceMembranes.pending,
        getDeviceSettings.pending,
        getDeviceAlarmSettings.pending,
        getDeviceAlarmHistory.pending,

        getDeviceThresholdsSettings.pending,
        postResetDeviceThresholdsSettings.pending
      ),
      (state) => {
        state.loading = LoadingStatus.LOADING
      }
    )
    .addMatcher(
      isAnyOf(
        getDevices.rejected,
        getMembranes.rejected,
        createDevice.rejected,
        addDevice.rejected,
        getDevice.rejected,
        getDevicePhones.rejected,
        getDeviceMembranes.rejected,

        getDeviceAlarmHistory.rejected
      ),
      (state) => {
        state.loading = LoadingStatus.FAILED
      }
    )
    .addMatcher(
      isAnyOf(updateDeviceAlarmSetting.fulfilled),
      (state, { payload }) => {
        state.loading = LoadingStatus.SUCCEEDED
        state.deviceAlarmSettings.data.find(
          (alarmSetting) => alarmSetting.alarmId === payload.alarmId
        ).enabled = payload.payload.enabled
      }
    )
})

export { reducer }
