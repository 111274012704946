import { get, post, put } from 'helpers/http.helper'

const Devices = (() => {
  const getDeviceStatsByStatus = () => get('/rodms/devices/stats/by-status')

  const getDevice = (id, params) =>
    get(`/rodms/devices/${id}`, {
      params,
    })

  const getDevicePhones = (id) => get(`/rodms/devices/${id}/phones`)

  const getDeviceMembranes = (id) => get(`/rodms/devices/${id}/membranes`)

  const createDevice = (payload) => post('/rodms/devices', payload)

  const tetherDevice = (payload) => post('/rodms/devices/tether', payload)

  const updateDevice = (id, payload) => put(`/rodms/devices/${id}`, payload)

  const updateTetheredDevice = (id, payload) =>
    put(`/rodms/devices/tether/${id}`, payload)
  const updateThresholdAlarm = (id, payload) =>
    put(`/rodms/devices/${id}/monitoring-settings`, payload)

  const updateFirmware = (id) => put(`/rodms/devices/${id}/firmware-update`)

  const updatePhones = (id, payload) =>
    put(`/rodms/devices/${id}/phones`, payload)

  const updateMembranes = (id, payload) =>
    put(`/rodms/devices/${id}/membranes`, payload)

  const getDeviceStats = (deviceId, type, params) =>
    get(`/rodms/statistics/devices/${deviceId}/${type}`, {
      params,
    })

  const getMembranes = (params) =>
    get(`/rodms/membranes`, {
      params,
    })

  const getDeviceLatestWaterLogs = (id, params) =>
    get(`/rodms/devices/${id}/logs/water/latest`, {
      params,
    })

  const getDeviceSettings = (id, params) =>
    get(`/rodms/devices/${id}/settings`, {
      params,
    })

  const updateDeviceSettings = (id) =>
    put(`/rodms/devices/${id}/request-settings`)

  const getDeviceAlarmSettings = (id, params) =>
    get(`/rodms/devices/${id}/alarm/settings`, {
      params,
    })

  const getDeviceThresholdsSettings = (id, params) =>
    get(`/rodms/devices/${id}/monitoring-settings`, {
      params,
    })

  const getDeviceAlarmHistory = (id, params) =>
    get(`/rodms/devices/${id}/alarms/logs`, {
      params,
    })

  const getDeviceAlarmHistoryXlsx = (id, params) =>
    get(`/rodms/devices/${id}/alarms/logs/xlsx`, {
      params,
      responseType: 'arraybuffer',
    })

  const postResetDeviceThresholdsSettings = (id, params) =>
    post(`/rodms/devices/${id}/monitoring-settings/defaults`, {
      params,
    })

  const updateDeviceAlarmSetting = (deviceId, alarmId, payload) =>
    put(`/rodms/devices/${deviceId}/alarm/${alarmId}/settings`, payload)

  const getDeviceLatestAnalogLogs = (id) =>
    get(`/rodms/devices/${id}/logs/analog/latest`)

  const getDeviceSettingValues = (name) =>
    get(`/rodms/devices/settings/${name}/values`)

  const updateDeviceSetting = (id, name, value) =>
    put(`/rodms/devices/${id}/settings/${name}`, { value })

  return {
    getDeviceStatsByStatus,
    getDevice,
    getMembranes,
    getDevicePhones,
    getDeviceMembranes,
    getDeviceStats,
    getDeviceLatestWaterLogs,
    getDeviceSettings,
    getDeviceAlarmSettings,
    getDeviceThresholdsSettings,
    getDeviceLatestAnalogLogs,
    getDeviceSettingValues,
    getDeviceAlarmHistory,
    getDeviceAlarmHistoryXlsx,
    postResetDeviceThresholdsSettings,
    createDevice,
    tetherDevice,
    updateDevice,
    updateTetheredDevice,
    updateFirmware,
    updatePhones,
    updateMembranes,
    updateDeviceSettings,
    updateDeviceAlarmSetting,
    updateDeviceSetting,
    updateThresholdAlarm,
  }
})()

export default Devices
